import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function Gaming() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='gaming-indus-pg'>
        <section className='indusry-main-banner gaming-bnr'>
            <video src="images/industry/gaming/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>   
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>Level Up Your <br />Gaming Experience</h2>
                            <p>Take your gaming business to the next level with Blitz Web Solution.</p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec gaming-about-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Game Website Development Services </h2>
                            <p>Embark on a digital journey that's as thrilling as your favorite games with our game web design and development services. Crafting immersive experiences is our forte. Our team of experts doesn't just create websites; we build virtual realms that capture the essence of your games. Elevate user engagement with responsive interfaces, seamless navigation, and visually captivating designs. From concept to code, we transform visions into pixel-perfect realities, ensuring every click takes players deeper into your gaming universe. With our game web development services, your website becomes a gateway to adventures, where aesthetics meet functionality. Level up your online presence today!</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/gaming/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec gaming-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Game Digital <br />Marketing Magic</h2>
                            <p>Amplify your game's impact with our dynamic game digital marketing services. We understand the art of connecting players to virtual worlds, ensuring your game stands out in the crowded arena. Our strategic approach leverages social media, influencer collaborations, and targeted campaigns to create an online buzz that resonates with gaming enthusiasts. From captivating trailers to immersive content, we wield the power of digital platforms to engage, excite, and convert. Elevate your game's presence, expand your community, and achieve player loyalty through our tailored digital marketing for games. Let's level up your game's reach and success together!</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/gaming/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry gaming-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Transforming Gameplay<br /> through Digital Expertise</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry gaming-mobile-app-industry'>
        <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/gaming/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>Game Mobile <br />App Development</h2>
                            <p>Step into the future of gaming with our comprehensive game mobile app services. From concept to launch, we breathe life into your game development dreams. Our team of skilled developers crafts immersive experiences that transcend screens, ensuring seamless gameplay and captivating visuals. Whether you're envisioning an action-packed adventure or a brain-teasing puzzle, we translate your ideas into interactive realities. With a focus on user-friendly interfaces and smooth functionality, our game development expertise guarantees a game-changing mobile app. Elevate player engagement, redefine user experiences, and embrace innovation with our dedicated game mobile app services. Your game is reimagined for the digital age.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/gaming/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec gaming-logo-design-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Logo Design</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/gaming/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/gaming/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/gaming/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/gaming/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Unleash Game Logo Design</h2>
                            <p>Forge an unforgettable gaming identity with our game logo design services. As a leading game logo design agency, we specialize in crafting visual masterpieces that encapsulate the essence of your game. Our creative wizards blend artistry and strategy, ensuring your logo becomes a symbol of your game's uniqueness. From sleek minimalism to intricate detailing, we tailor designs that resonate with players and leave a lasting mark. Elevate your game's recognition with a logo that narrates its story at a glance. Discover the power of visual branding through our game logo design services, where innovation meets immersion. </p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                {/* <h4>Expert Designers, Exceptional Results</h4> */}
                                <h2>Benefits of Gaming <br /> Web Design</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Elevated Player Engagement</h4>
                                        <p>Game digital services create immersive experiences that captivate players. From interactive interfaces to engaging gameplay mechanics, these services keep players hooked, driving longer sessions and repeat visits. </p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Expanded Community Reach</h4>
                                        <p>Leveraging digital platforms, game services connect players globally. Online multiplayer modes, social integration, and community forums foster connections, creating a vibrant player community that transcends borders.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Efficient Updates and Patches</h4>
                                        <p>Digital services enable the swift distribution of updates and patches. Developers can seamlessly address bugs, enhance gameplay, and introduce new content, ensuring players always have a fresh and optimized experience.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Monetization Opportunities</h4>
                                        <p>Game digital services offer various monetization avenues, from in-game purchases to downloadable content. Developers can offer cosmetic items, expansions, and more, enhancing revenue streams while allowing players to personalize their experience.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Real-time Analytics and Insights</h4>
                                        <p>Through digital services, developers gain insights into player behaviour, preferences, and engagement patterns. These analytics inform decision-making, enabling the refinement of gameplay and tailored content delivery.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/gaming/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Global Tournaments and Events</h4>
                                        <p>Digital services facilitate global tournaments and events, enhancing competition and player interaction. Players can showcase their skills, earn rewards, and engage in a dynamic competitive environment that fuels excitement and dedication.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry gaming-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Empowering Gamers with<br /> Our Digital Arsenal</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <IndustryIconSec />

        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
