import React, { useEffect } from 'react';
import DigitalServiceTab from '../components/DigitalServiceTab';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import { LightgalleryItem } from "react-lightgallery";
import DigitalMarktetingPrice from '../components/includes/DigitalMarketingPrice';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function DigitalMarkteting() {

    document.title ="E-Commerce & Local Digital Marketing Services In London UK "; 
	document.getElementsByTagName("META")[2].content="Blitz Web Solution is a digital marketing agency in UK. We offer branding, SEO, SMM, ORM, & PPC services to boost your online presence and business growth. ";


    useEffect(() => {




        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>
   
    <section className='inner-banner digital-mrkt-banner'>
        <video src="images/digital-mrkt/banner.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='inr-bnr-txt '>
                        <h2>Reach New Heights with Expert Digital Marketing Services</h2>
                        <p>Partner us with the best digital marketing agency services and take your brand to the next level within a short period of time. Blitz Web Solutions provides the best digital marketing services that take your brand to new heights.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>

                </div>
            </div>
        </div>
    </section>

    <section className='about-sec-digital-mrkt'>
        <img className='about-line-digital' src='images/digital-mrkt/about-line-mid.png' alt=''></img>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>Overview</h4>
                        <h2>Conquer the Online Landscape <br />with Our Marketing Expertise</h2>
                        <p>Work with an experienced team of professional digital marketing services and commit to your success. We are providing our digital marketing services in the UK, crucial for business in the digital age. Partnering with Blitz Web Solution will let you benefit from online reputation management to SEO services.<br /> 
                        Working with Blitz Web Solution, you will get affordable digital marketing services that will boost your brand voice. Regardless of the size of your business, our team of professional digital marketing service providers will make sure to work on branding so you will get the complete attention of the audience you deserve. </p>
                    </div>
                </div>
                <div className='col-sm-12 digit-about-img-mid'>
                    <img className='about-mid-digital' src='images/digital-mrkt/about-mid-img.png' alt=''></img>                    
                </div>
            </div>
        </div>
    </section>

<DigitalServiceTab />


    <section className='cta-sec cta-ecommerce-sec cta-digital-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='cta-side-img cta-side-img-web'>
                        <img src='images/digital-mrkt/cta-side-img.png' alt=''></img>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='cta-txt web-cta-txt hd-txt'>
                        <h2>Unleashing the Power of Digital Marketing</h2>
                    </div>
                    <div className='bnr-btn about-btn'>
                        <a href='#.' className='get-btn popup-btn'>Get Started</a>
                        <a href='#.' className='chat-btn chats'>Live Chat</a>
                    </div>
                    <div className='cta-tel'>
                        <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                        <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='portfolio-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
        <div className='container'>
            <div className='row'>
                <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                <img className='port-af-img' src='images/port-af.png' alt=''></img>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>A Glimpse Of Work!</h4>
                        <h2>Our Latest Creative Work</h2>
                    </div>
                </div>
                <div className='col-sm-12 p0 main-port-div'>
                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/1.png">
                                <img src='images/digital-mrkt/1.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/2.png">
                                <img src='images/digital-mrkt/2.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/3.png">
                                <img src='images/digital-mrkt/3.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/4.png">
                                <img src='images/digital-mrkt/4.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/5.png">
                                <img src='images/digital-mrkt/5.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>

                    <div className='col-sm-4'>
                        <div className='port-bx'>
                            <LightgalleryItem src="images/digital-mrkt/6.png">
                                <img src='images/digital-mrkt/6.png' alt=''></img>
                            </LightgalleryItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='pricing-sec digital-pricing-sec-pg'>
    <h2 className="mobile-outline-txt-st outline-txt-pricing">Pricing</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Affordability With Quality</h2>
                        <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 pricing-main-col'>
                <DigitalMarktetingPrice />
                </div>
            </div>
        </div>
    </section>
    

    <section className='why-choose-sec-inner'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>Blitz Web Solution is a digital marketing services company who works for results and thrives to take your business to the new levels.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/digital-mrkt/help-icon-1.png' alt='' />
                                <h3>Proven Track<br /> Record</h3>
                                <p>We have a strong and proven track record of delivering 360 marketing services for a diverse range of clients. Our expertise and experience have helped businesses achieve significant growth.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/digital-mrkt/help-icon-2.png' alt='' />
                                <h3>Customized <br />Strategies</h3>
                                <p>We understand that every business is unique, so we tailor our digital marketing strategies to align with your specific goals and target audience. Our team conducts in-depth research and analysis.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/digital-mrkt/help-icon-3.png' alt='' />
                                <h3>Data-Driven <br />Approach</h3>
                                <p>We believe in data-backed decision-making. Our digital marketing campaigns are continuously monitored and optimized based on real-time analytics. This allows us to identify opportunities and refine strategies.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/digital-mrkt/help-icon-4.png' alt='' />
                                <h3>Transparent &<br /> Collaborative</h3>
                                <p>We value open communication and collaboration with our clients. Throughout the entire process, we keep you informed about the progress, results, and any changes in strategies.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <IndustryIconSec />

    <section className='process-inner-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Strategy and Planning</h4>
                                    <p>The first step in our digital marketing process is to develop a comprehensive strategy based on your business goals.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Implementation and Execution</h4>
                                    <p>Once the strategy is finalized, we move on to the implementation phase and implement the plan.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Monitoring and Optimization</h4>
                                    <p>As your campaigns run, we continuously monitor their performance using advanced analytics tools. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Analysis and Reporting</h4>
                                    <p>Regular analysis and reporting are vital components of our process. We provide you with detailed insights.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>How We Make Your Business Distinctive?</h2>
                        <p>At Blitz Web Solution, our digital marketing process is a carefully orchestrated journey to elevate your brand's online presence and drive results. We begin with a thorough analysis of your business, industry, and target audience to craft a tailored strategy. Our team implements a multi-channel approach encompassing SEO, PPC, social media, content marketing, and more. As campaigns unfold, we continuously monitor performance, leveraging real-time data to optimize and fine-tune strategies. Our commitment to transparency means you're always in the loop, with regular reporting and open communication. Join us on this transformative digital voyage and unlock the full potential of your brand in the digital realm.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <TestiSec />
    <Popup />
    </>
  )
}
