import React, { Component } from "react";

import Slider from "react-slick";

export default class TestiSlider extends Component {
    render() {
        const settings = {
            dots: false,
            arrows:true,
            infinite: true,
            centerMode: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <section className='testi-sec'>
              <h2 className="mobile-outline-txt-st outline-txt-consultation">Testimonial</h2>
              <img className='blitz-logo-ftr-st' src='images/blitz-logo-ftr.png' alt=''></img>
            <div className='container'>
                <div className='row'>
                    <img className='testi-mobile' src='images/testi-mobile.png' alt='' ></img>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h4>What Our Clients Are Saying</h4>
                            <h2>Over 1000+ Happy Clients and Growing</h2>
                        </div>
                    </div>
                    <div className='col-sm-12 p0 testi-main-col'>
                    <Slider {...settings}>
                        {/* <div className='testi-slider'> */}
                            <div>
                                <div className='testi-box'>
                                    <img src='images/quote.png' alt=''></img>
                                    <p>Blitz Web Solution is a one-stop solution for everything an organization requires. Their skilled developers combined quality with timeliness and created an outstanding website for us. Truly amazed by their work.</p>
                                    <h3>Steve Martin</h3>
                                    <div className='star-testi'>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='testi-box'>
                                    <img src='images/quote.png' alt=''></img>
                                    <p>Blitz Web Solution has been a strength for our corporation from the beginning. They have delivered high-value work every single time. I cannot recommend them highly enough. Best company to work with!</p>
                                    <h3>James Dunkin</h3>
                                    <div className='star-testi'>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='testi-box'>
                                    <img src='images/quote.png' alt=''></img>
                                    <p>I am truly amazed with the abilities of Blitz Web Solution. Their services are unparalleled because of their expertise, rational charges, rapid delivery times, and improved support. Highly recommended for website design.</p>
                                    <h3>Leah Tyler</h3>
                                    <div className='star-testi'>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='testi-box'>
                                    <img src='images/quote.png' alt=''></img>
                                    <p>Blitz Web Solution has a strong focus on customer satisfaction. They go above and beyond to ensure that their clients are happy with the services they receive. They provide timely updates and are always available to answer questions and provide support.</p>
                                    <h3>Bernard Martinez</h3>
                                    <div className='star-testi'>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div>
                                <div className='testi-box'>
                                    <img src='images/quote.png' alt=''></img>
                                    <p>Working with Rob at blitz web solution has been an amazing experience. His team had worked really hard on my project. They did ppc marketing for my website and the results are amazing. I want to recommend them to others.</p>
                                    <h3>Javier Davis</h3>
                                    <div className='star-testi'>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
          
        );
      }
    }