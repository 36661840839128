
import React, { useEffect } from 'react';

export default function Error() {
  useEffect(() => {


    setTimeout(function(){
      window.location.href = 'https://www.blitzwebsolution.co.uk/';
   }, 5000);

});

  return (
    <section className='inner-banner about-banner error-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                    <video src="images/404.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                        <p>Sorry - File not Found!</p>
                        <a href='/'>Back to Home</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
