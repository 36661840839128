import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function Contact() {

    document.title ="Contact Us - Blitz Web Solution "; 


    useEffect(() => {
        window.jQuery( ".txtOnly" ).keypress(function(e) {
            var key = e.keyCode;
            if (key >= 48 && key <= 57) {
                e.preventDefault();
            }
        });
        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>
    
    <section className='inner-banner about-banner contact-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                        <h2>Contact Us</h2>
                        <p>Get in touch with us for any inquiries, feedback, or collaboration opportunities. We're here to assist you. Reach out today!</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='contact-inner-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>Contact Now</h4>
                        <h2>Have a Question? Write a Message</h2>
                        <p>Need answers? Reach out and get your questions answered by sending us a message. We're here to help! </p>
                    </div>
                </div>
                <div className='col-sm-12 conact-main-div'>
                    <div className='col-sm-6'>
                        <div className='contact-form'>
                        <form action="https://www.blitzwebsolution.co.uk/mail/" className="jform validate main-pop" method="POST">
                        <div className="col-md-12">
                            <div className="control-group">
                                <i className="fa fa-user" aria-hidden="true"></i>
                                <input className='txtOnly'  type="text" name="cn" placeholder="Enter Name" required />
                    
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="control-group">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <input type="email" name="em" className="required email" placeholder="Enter your email here" required="" />
                            </div>
                        </div>
                        <div className="col-md-12 phonecode">
                            <div className="control-group clearfix">
                                <div className="numberarea">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <input onInput={(e) => {if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0,e.target.maxLength);}} type = "number"    maxLength = {12}  name="pn" placeholder="Enter Phone" required />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="control-group clearfix ">
                                <div className="textarea_auto">
                                    <i className="fa fa-comments" aria-hidden="true"></i>
                                    <textarea className="form-control" name="msg" placeholder="Talk about your project" required></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12">
                            <div className="control-group clearfix  submit-btn-pop-st">
                                <input type="submit" className="btn btn-default pop_btn submit-btn fspx-23  ls-medium d-block w-100 fw-bold" value="Submit" />
                                <input type="hidden" id="lead_area_popup" name="lead_area" value="for $119" />
                                <input type="hidden" id="lead_org_price" name="lead_org_price" value="19" />
                                <input type="hidden" name="send" value="1" />
                                <input type="hidden" name="service_id" value="5669"   className="service_id" />
                                
                                <input type="hidden" name="lb_source" value="" />
                                <input type="hidden" name="lb_source_cat" value="" />
                                <input type="hidden" name="lb_source_nam" value="" />
                                <input type="hidden" name="lb_source_ema" value="" />
                                <input type="hidden" name="lb_source_con" value="" />
                                <input type="hidden" name="lb_source_pho" value="" />
                                <input type="hidden" name="lb_source_off" value="" />
                                
                                <input type="hidden" name="fullpageurl" value="" />
                                <input type="hidden" name="pageurl" value="" />
                                
                                <input type="hidden" name="ip2loc_ip" value="" />
                                <input type="hidden" name="ip2loc_isp" value="" />
                                <input type="hidden" name="ip2loc_org" value="" />
                                <input type="hidden" name="ip2loc_country" value="" />
                                <input type="hidden" name="ip2loc_region" value="" />
                            </div>
                        </div>
                    </form>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='cntct-form-r-side'>
                            <ul>
                                <li><a href='tel:207-072-7323 '><i className="fa fa-phone" aria-hidden="true"></i> 207 072 7323 </a></li>
                                <li><a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa fa-envelope" aria-hidden="true"></i> sales@blitzwebsolution.co.uk</a></li>
                                <li><a href='#.'><i className="fa fa-map-marker" aria-hidden="true"></i> <p>187 East Warm Springs Rd, SB298 Las Vegas, NV, 89119 </p></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section className='why-choose-sec-inner'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>Trusted expertise, exceptional quality, and unrivaled customer satisfaction—our commitment to excellence sets us apart in delivering unparalleled solutions.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-1.png' alt='' />
                                <h3>Unparalleled Expertise</h3>
                                <p>Our team of seasoned professionals delivers unmatched knowledge and industry experience.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-2.png' alt='' />
                                <h3>Outstanding Quality</h3>
                                <p>We pride ourselves on delivering exceptional work that exceeds expectations.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-3.png' alt='' />
                                <h3>Client-Centric Approach</h3>
                                <p>We prioritize your needs, ensuring personalized solutions tailored to your requirements.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-4.png' alt='' />
                                <h3>Timely Delivery</h3>
                                <p>Count on us for prompt, reliable, and on-time completion of projects.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-5.png' alt='' />
                                <h3>Competitive Pricing</h3>
                                <p>We offer competitive rates without compromising on the quality of our services.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-6.png' alt='' />
                                <h3>Customer Satisfaction</h3>
                                <p>Our commitment to your satisfaction drives us to go the extra mile, every time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <IndustryIconSec />

    {/* <section className='process-inner-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Discovery and Analysis</h4>
                                    <p>The first step in any digital solution involves understanding your business needs, goals, and challenges. Through thorough analysis, we identify opportunities for improvement and potential solutions. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Design and Development</h4>
                                    <p>Once the strategy is defined, our skilled team brings the concept to life through thoughtful design and development. From user interface (UI) design to backend implementation, we do them all.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Testing and Quality Assurance</h4>
                                    <p>Rigorous testing and quality assurance are vital to delivering a reliable and error-free digital solution. We conduct various tests to verify functionality, usability, security, and performance. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Deployment and Support</h4>
                                    <p>After successful testing, the digital solution is deployed to the desired platforms. We offer continuous support and maintenance to ensure the solution remains up-to-date.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>Our Streamlined <br />Process</h2>
                        <p>At Blitz Web Solutions, we take pride in our efficient and result-driven approach to every project. From the initial consultation, we meticulously plan and gather requirements to ensure a clear roadmap. Our talented team then brings your vision to life, combining creativity and cutting-edge technology to build exceptional solutions. Rigorous testing and quality assurance guarantee a seamless experience. Once deployed, we continue to provide ongoing support and maintenance, ensuring your success doesn't waver. With a focus on transparency, timely communication, and delivering superior results, our streamlined process ensures your project is a resounding success, every step of the way.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <TestiSec />
    <Popup />
    </>
  )
}
