import React, { Component } from "react";
import { Link } from 'react-router-dom';

export default class IndustryIconSec extends Component {
    render() {
        
        return (
            <section className='what-sec-inner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>What’re You Looking For?</h2>
                            <p>We exude passion in each project we take on. Our process is simple and the result of coordination, <br />creative genius and professionalism of our creatives.</p>
                        </div>
                    </div>
                    <div className='col-sm-12 p0 what-main-col-inr'>
                        <div className='what-main-list-dv'>
                            <ul>
                                <li>
                                    <Link className="nav-link" to="/education">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-1.png' alt=''></img>
                                            <h4>Education</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/gaming">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-2.png' alt=''></img>
                                            <h4>Gaming</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/agriculture">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-3.png' alt=''></img>
                                            <h4>Agriculture</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/construction">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-4.png' alt=''></img>
                                            <h4>Construction</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/automotive">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-5.png' alt=''></img>
                                            <h4>Automotive</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/corporate">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-6.png' alt=''></img>
                                            <h4>Corporate</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/dental">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-7.png' alt=''></img>
                                            <h4>Dental</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/fashion">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-8.png' alt=''></img>
                                            <h4>Fashion</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/fitness">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-9.png' alt=''></img>
                                            <h4>Fitness</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/health-care">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-10.png' alt=''></img>
                                            <h4>Health Care</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/non-profit">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-11.png' alt=''></img>
                                            <h4>Non Profit</h4>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="nav-link" to="/real-estate">
                                        <div className='what-bx-inr'>
                                            <img src='images/nav-icon-12.png' alt=''></img>
                                            <h4>Real Estate</h4>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
                    
        );
      }
    }