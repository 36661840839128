import React, { Component } from "react";

import Slider from "react-slick";

export default class WebServSlider extends Component {
    render() {
        const webserslider = {
            dots: false,
            arrows:true,
            vertical:true,
            infinite: true,
            centerMode: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    dots:true,
                    arrows:false,
                    vertical:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    vertical:false,
                    dots:true,
                    arrows:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
           
        <Slider {...webserslider}>                       
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-1.png" alt="" ></img>
                        <h4>Mobile Compatible</h4>
                        <p>Our top e-commerce web developers design a website with full mobile compatibility with a hassle-free approach.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-2.png" alt="" ></img>
                        <h4>Provide Incredible Experience</h4>
                        <p>Our expert e-commerce website developers with all their hard work attain an all-in-one method to get a responsive website.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-3.png" alt="" ></img>
                        <h4>Checkout Opportunities</h4>
                        <p>Our eCommerce developers induce some unique checkout options for smooth delivery management.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-4.png" alt="" ></img>
                        <h4>Payment Gateways</h4>
                        <p>Our services include an easy payment procedure. We provide more than 11 active payment procedures integrated with the customer's website.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-5.png" alt="" ></img>
                        <h4>Product Categorization</h4>
                        <p>Our creative eCommerce development company provides you with a detailed product-categorized area to showcase your products.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-6.png" alt="" ></img>
                        <h4>Shopping Cart</h4>
                        <p>We provide you with a simple yet elegant platform for online shopping for a variety of options for your customers.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-7.png" alt="" ></img>
                        <h4>Efficient content management</h4>
                        <p>We induce efficient content management to fulfill the areas with keywords to rank your website high.</p>
                    </div>
                </div>
                <div>
                    <div className="web-serv-box">
                        <img src="images/ecommerce/services-icon-8.png" alt="" ></img>
                        <h4>Effective data and product import</h4>
                        <p>We help you to screen your effective data and your product's import carefully with smartphones and other devices.</p>
                    </div>
                </div>
            </Slider>
                    
        );
      }
    }