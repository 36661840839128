import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import { LightgalleryItem } from "react-lightgallery";
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function WebsiteDesign() {
    document.title ="Professional Web Design & Development Services In London "; 
	document.getElementsByTagName("META")[2].content="Building your brand? Get your website design and developed with Blitz Web Solution. Our professionals are available for creating your brand with new technologies. ";

    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>
          <section className='inner-banner website-banner'>
              <video src="images/website-design/banner.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
              <div className='container'>
                  <div className='row'>
                      <div className='col-sm-6'>
                          <div className='inr-bnr-txt '>
                              <h2>Crafting Digital Success through Professional Website Design Services</h2>
                              <p>If you are looking for custom web development services, then look no further than Blitz Web Solutions, as we provide professional web development services for businesses of all sizes. </p>
                              <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                          </div>
                      </div>
                      <div className='col-sm-6'>

                      </div>
                  </div>
              </div>
          </section>
          <section className='about-sec-webdesgin'>
          <h2 className="mobile-outline-txt-st outline-txt-1">Website</h2>
            <div className='container'>
            
                <div className='row'>
                    <img className='wordpress-logo-st-web' src='images/website-design/wordpress-logo-st.png' alt=''></img>
                    <div className='col-sm-5'>
                        <div className='hd-txt'>
                            <h4>Digital Excellence Made Easy</h4>
                            <h2>Unleash Your Online Potential with Web Design Wizardry</h2>
                            <p>Blitz Web Solutions take pride in offering the best web developme­nt services that unlock online pote­ntial. Our team of skilled we­b designers is committed to cre­ating captivating, functional websites that leave­ a lasting impression on the target audie­nce.<br />Our custom web­ development services are­ personalized to align with your distinct business obje­ctives. As the virtual repre­sentation of your brand, we strive towards cre­ating an aesthetically appealing and use­r-friendly interface. Our e­xperienced de­signers employ industry standards encompassing UI/UX de­sign in crafting websites that attract visitors and convert the­m into potential leads, so halt your search for web developer services and contact us today. </p>
                        </div>
                    </div>
                    <div className='col-sm-7'>
                        <div className='about-webdesgin-side-img'>
                            <img className='about-s-web-img-1' src='images/website-design/about-side-img-1.png' alt=''></img>
                            <img className='about-s-web-img-2' src='images/website-design/about-side-img-2.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          <section className='web-services-sec'>
          <h2 className="mobile-outline-txt-st outline-txt-services">Services</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Our Services</h2>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 web-serv-main-col'>
                            <div className='col-sm-5 web-serv-lft-col-st'>
                                <div className='hd-txt'>
                                    <h2>What Our Web Development Services Have To Offer?</h2>
                                    <p>Blitz Web Solutions offers custom web deve­lopment services in the UK that go be­yond aesthetics. Our te­am of developers utilizes cutting-edge technologie­s and coding practices to create search-engine-optimized we­bsites that look great, function flawlessly, and load quickly on all de­vices. With a deep unde­rstanding of programming languages like HTML, CSS, and JavaScript, we bring our client's visions to life with precision and fine­sse.</p>
                                    <div className='bnr-btn about-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                        <a href='#.' className='chat-btn chats'>Live Chat</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-7'>
                                <div className='web-serv-main-list'>
                                    <ul>
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>Web Portal</h2>
                                                <p>Our professionals create a web portal that aids in the rationalization of corporate procedures. Our specialists use their skills to create mobile-friendly web portals that help your clients with their business, and generate a strong online presence.</p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>WordPress Websites</h2>
                                                <p>Our innovative website-building firm creates WordPress websites to display your company's services and goods in your market. The core WordPress development team meticulously creates the stage for a non-technical audience. </p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>E-Commerce Websites</h2>
                                                <p>Blitz Web Solutions has created high-end e-commerce websites. We collaborate with customers to create a high-functioning e-commerce website that generates sales quickly and effectively. </p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>Corporate Websites</h2>
                                                <p>On occasion, corporate websites improve your internet presence. Our expert web designers create great corporate websites to help you build long-term relationships with your target audience.</p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>WooCommerce Websites</h2>
                                                <p>WooCommerce comes with a lot of benefits that includes ease of use where it integrates with Wordpress seamlessly, making it easy to set up and manage an online store without having extensive technical knowledge.</p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                        
                                        <li>
                                            <div className='web-serv-box-m'>
                                                <h2>Shopify Websites</h2>
                                                <p>Shopify is a leading e-commerce platform that provides businesses with a comprehensive set of tools to build, manage, and grow their online stores that provide a lot of integrations with ease.</p>
                                                <a href='#.'><i className="fa-solid fa-arrow-right-long"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </section>
          <section className='cta-sec cta-web-sec'>
                <div className='container'>
                    <div className='row'>                        
                        <div className='col-sm-6'>
                            <div className='cta-side-img cta-side-img-web'>
                                 <img src='images/website-design/cta-side-img.png' alt=''></img> 
                                {/* <video src="images/website-design/cta-side-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='cta-txt web-cta-txt hd-txt'>
                                <h2>Unleash Your Online Potential </h2>
                            </div>
                            <div className='bnr-btn about-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                            <div className='cta-tel'>
                                <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                                <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='portfolio-sec'>
                <div className='container'>
                    <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
                    <div className='row'>
                        <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                        <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                        <img className='port-af-img' src='images/port-af.png' alt=''></img>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h4>A Glimpse of Our Work!</h4>
                                <h2>Some Of Our Best Work</h2>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 main-port-div'>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                <LightgalleryItem src="images/website-design/port-1-1.jpg">
                                    <img src="images/website-design/port-1.png" alt='' />
                                </LightgalleryItem>
                                    
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/website-design/port-2-2.jpg">
                                        <img src="images/website-design/port-2.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/website-design/port-3-3.jpg">
                                        <img src="images/website-design/port-3.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/website-design/port-4-4.jpg">
                                        <img src="images/website-design/port-4.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/website-design/port-5-5.jpg">
                                        <img src="images/website-design/port-5.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/website-design/port-6-6.jpg">
                                        <img src="images/website-design/port-6.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='pricing-sec web-pricing-sec-pg'>
                <div className='container'>
                <h2 className="mobile-outline-txt-st outline-txt-pricing">Pricing</h2>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Affordability With Quality</h2>
                                <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 pricing-main-col'>
                            <div className='pricingtab-slider'>
                                {/* <div className='testi-slider'> */}
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Startup Web Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>149</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 3 Page Website</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 5 Stock Images (Industry Specific)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Contact Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 3 Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee *</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive for $125</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> CMS for Additional $150</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Professional Web Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>594</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 10 Unique Pages Website</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Contact us Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Appointment Scheduling CTA Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 10 Stock images (Industry Specific)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 5 Custom Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider Banner</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> FREE Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive for Additional $149</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Elite Web Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>994</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 15 Custom Pages Website</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Conceptual and Dynamic Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Striking Hover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Reservation/Appointment Tool (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Payment Gateway Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Contact Forms</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Lead Capturing Forms &amp; CTA</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Newsletter Subscription CTA (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Newsfeed Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Social Media Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images (Industry Specified)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Unique Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> <strong>Add on</strong></li>
                                                <li><i className="fa-solid fa-arrow-right"></i> On Page &amp; Technical SEO for $225</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Business Web Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>1524</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Up to 25 Custom Pages Website</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unique Interactive, Dynamic &amp; High End Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom WP (OR) Custom PHP Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Special Striking Hover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Liquid Responsive Layout </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multi Lingual (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Reservation/Appointment Tool (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Payment Gateway Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Dynamic CTA Forms</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Lead Capture CTA Forms</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Bar </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> User Login </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multiple Custom Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images (Industry Specific)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Live Feeds of Social Networks integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Installation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Industry Specified Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> <strong>Add on</strong></li>
                                                <li><i className="fa-solid fa-arrow-right"></i> On Page &amp; Technical SEO for $225</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Business Pro Web Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>2594</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Pages Website</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom 2D Explainer Video Animation </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Voice Over &amp; Sound Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Professional Script Writing</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Storyboard Creation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> UI / UX Interactive &amp; Dynamic HD Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> SEO Meta Tags</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Liquid Responsive </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom WP (or) Custom PHP Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider Banner</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Made Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Special Hoover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Payment Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multi Lingual (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Dynamic Forms (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Bar</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Live Feeds of Social Networks integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Image alt text</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> SEO Internal Linking</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Industry Specified Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee </li>
                                                <li><i className="fa-solid fa-arrow-right"></i> FREE On Page &amp; Technical SEO</li>
                                                
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Customized Web Portal Package</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>4494</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                            <ul className=" jack jack2">
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Custom Design &amp; Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Dating Portal, Job Portal, Professional Network Portal, Social Network Portal, Restaurant Portal, Medical Portal, Enterprise Portal (Any One)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unique, User Friendly, Interactive, Dynamic, High End UI Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Interactive Sliding Banners</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Special Hoover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Stock Images</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  User Signup Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Client/User Dashboard Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom Coding and Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Online Payment Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Multi Lingual (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom Dynamic Forms (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Shopping Cart Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Database Creation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Signup Automated Email Authentication</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Web Traffic Analytics Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  3rd Party API Integrations</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Search Bar for Easy Search</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Live Feeds of Social Networks integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Search Engine Submission</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Module-wise Architecture</li>       
                                                <li><i className="fa-solid fa-arrow-right"></i>  Aware Winning Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Value Added Services</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Source Files</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Dedicated Project Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Money Back Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  *NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Content Management System (Custom)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Extensive Admin Pane</li>                                         
                                            </ul>
                                                
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className='pricing-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Affordability With Quality</h2>
                                <p>Web development services in UK can sometimes become heavy on the pockets but the best web development services company can loosen up your pockets by offering pocket-friendly packages, like the ones we are providing.</p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 pricing-main-col'>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='why-choose-sec-inner'>
                <div className='container-fluid'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Why Choose Us?</h2>
                                <p>Blitz Web Solutions is your best bet for web development consulting services, as we are one of the leading companies where you can enjoy flexibility and versatility. </p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                            <div className='why-slider-inner'>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/website-design/help-icon-1.png' alt='' />
                                        <h3>Expertise & Experience</h3>
                                        <p>Our team of highly skilled designers and developers are experts in what they do and they can provide you with the best of the websites with their years of experience. </p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/website-design/help-icon-2.png' alt='' />
                                        <h3>Customized Solutions</h3>
                                        <p>We not only make your site according to the trends, but we make sure that it is customized according to your needs and requirements by making it fully custom.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/website-design/help-icon-3.png' alt='' />
                                        <h3>User-Friendly Designs</h3>
                                        <p>When designing and developing your website, we make sure that we make it responsive so it looks best on all mediums and also makes them really easy to use.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/website-design/help-icon-4.png' alt='' />
                                        <h3>Attention to Detail</h3>
                                        <p>We are a company that focuses on attention to detail and makes your website unique so it can stand out among the competitors and looks good to your clients.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/website-design/help-icon-5.png' alt='' />
                                        <h3>Timely Delivery</h3>
                                        <p>We do not compromise on our deliveries, as we make sure that all the projects are perfectly aligned with the promised deadlines, so we can deliver them on time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IndustryIconSec />

            <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='process-inner-main-col'>
                                <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                                <ul>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>01</h4>
                                            </div>
                                            <h4>Planning & Strategy</h4>
                                            <p>When you handover your project to us, our team brainstorm the details and then strategize how to start the project</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>02</h4>
                                            </div>
                                            <h4>Design & Development</h4>
                                            <p>Once we have made a strategy, then we implement it on the design and then develop the website according to the design.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>03</h4>
                                            </div>
                                            <h4>Testing & Refinement</h4>
                                            <p>After the development has been completed, we hand it over to the testing team who test it for bugs, and problems and solve them.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>04</h4>
                                            </div>
                                            <h4>Launch & Maintenance</h4>
                                            <p>Once everything is done and checked, we launch it and make it live, then we maintain it according to the needs. </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='hd-txt'>
                                <h2>How We Create <br />Outstanding Websites</h2>
                                <p>Blitz Web Solutions provides a compre­hensive solution for online pre­sence enhance­ment through our integrated web design and deve­lopment services. A collaborative­ approach allows us to understand your brand, target audience­, and business objectives close­ly. This understanding leads us to create­ websites that refle­ct your brand identity effective­ly and communicate your message cle­arly. 
                                    Whether you need a simple informational website or a robust e-commerce platform, Blitz Web Solutions has the expertise to deliver. We are dedicated to staying at the forefront of industry trends and best practices, ensuring that your website is equipped with the latest features and functionalities.
                                    </p>
                                <div className='bnr-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                    <a href='#.' className='chat-btn chats'>Live Chat</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TestiSec />
            <Popup />
    </>
  )
}
