import React from 'react'
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';

export default function PrivacyPolicy() {
  return (
    <>
    <section className='inner-banner about-banner terms-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                        <h2>Privacy Policy</h2><br />
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="service pad-tb terms-main-div">
   <div className="container" bis_skin_checked="1">
      <div className="row" bis_skin_checked="1">
         <div className="col-lg-12 block-1" bis_skin_checked="1">
            <div className="common-heading text-l pl25" bis_skin_checked="1">
               <h2>Custom Web Design Services </h2>
               <p>Blitz Web Solution strives to provide maximum advantage to customers worldwide with their affordable, yet high-quality website design services in UK. We give your target audience the best experience with a powerful website design that helps your business stay ahead of the competition. </p>
               <p>We ensure that all the requirements of our customers are met without any hurdle or hassle. Our website designers exceed clients’ expectations with a cutting-edge approach for the websites to stand out. Need a flawlessly designed website? Contact Blitz Web Solution today and experience the change.</p>
               <h2>	What information do we gather? </h2>
               <p>
                  This privacy plan is set to explain our policies regarding customer`s personal data that is collected for our use. When you place a query online, you will only need to give some basic information to us by filling out a simple query form so that we could contact you to discuss the need. You will only need to enter your name, e-mail address, phone number or country.
               </p>
               <h2> Do we disclose any information to outside parties? </h2>
               <p>
                  We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include our subsidiaries and trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
               </p>
               <h2> How do we protect your information? </h2>
               <p>
                  Blitz Web Solution.com uses a secure server for the purpose of securing your private information. All your private/credit information sends out via Secure Socket Layer (SSL) technology that is used to keep the information confidential when you place your order online.
               </p>
               <h2> What do we use your information for? </h2>
               <p>
                  We use the personal data for handling orders. Blitz Web Solution and its members use your personal data and other personally non-identifiable information that will help us in handling your orders properly and to better respond to your complexity. Furthermore, your information may be used for the following ways:
                  To improve our website features (by the feedbacks we receive from you)
                  To keep you updated with our latest offers and happening
                  To improve our customer services
               </p>
               <h2> The General Data Protection Regulation Imply </h2>
               <p>
                  We do not transfer data to other parties that gives out your personally identifiable information. An individual's data that you will give will be kept confidential and you will have complete know of it. This does not include our subsidiaries and trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
               </p>
               <h2> Consent </h2>
               <p>
                  By consenting to this privacy notice you are giving us permission to process your personal data specifically for the purpose identified. Consent is required for Blitz Web Solution to process both types of personal data, but it must be explicity given. Where we are asking for your sensitive personal data we will always tell you why and how the information will be used. You may withdraw consent at any time by Call or Via Email as per the standard Withdrawal of Consent Procedure.
               </p>
            </div>
         </div>
      </div>
   </div>
</section>

<TestiSec/>
    <Popup />
    </>
  )
}
