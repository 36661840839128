import React, { Component } from "react";
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default class DigitalServiceTab extends Component {
    render() {
        const digiltaservtab = {
            dots: true,
            arrows:false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <>
            <section className='digtal-mrkt-service-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-services">Services</h2>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Our Services</h2>
                            </div>
                            <Tabs
                              defaultActiveKey="home"
                              transition={true}
                              id="noanim-tab-example"
                              className="mb-3"
                            >
                              <Tab eventKey="home" title="SEO">
                                  <Slider {...digiltaservtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-1.png" alt=""></img>
                                                    <h3>Keyword Ranking</h3>
                                                    <p>One of the essential factors of search engine optimization is keyword ranking. The number of keyword searches and the volume of keyword ranking helps a website.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-2.png" alt=""></img>
                                                    <h3>Activities Reporting</h3>
                                                    <p>We provide satisfactory reporting to our valued customers regarding Google Analytics, domain, up-to-date hosting, HTML data, and link reports.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-3.png" alt=""></img>
                                                    <h3>Monitoring Activities</h3>
                                                    <p>Blitz Web Solution focuses on a solution-oriented approach to SEO and monitors the progress and milestones with appropriate techniques.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-4.png" alt=""></img>
                                                    <h3>24/7 Traffic Updates</h3>
                                                    <p>We allow you to access analytics data, website demographics, traffic stats, and other updates in real time, 24 hours a day and seven days a week.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-5.png" alt=""></img>
                                                    <h3>Social Analytics</h3>
                                                    <p>You can get real-time stats on various social media channels and platforms, i.e., Twitter, Facebook, Instagram, and others, with our SEO services.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SEO/serv-icon-6.png" alt=""></img>
                                                    <h3>Reputation Management</h3>
                                                    <p>To get a maximum online presence for your brand, we have a team of highly qualified individuals to improve your reputation on online reputation websites.</p>
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                              <Tab eventKey="profile" title="SMM">
                                  <Slider {...digiltaservtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-1.png" alt=""></img>
                                                    <h3>Content Marketing</h3>
                                                    <p>Incapable of coming in front of the right audience? The content marketing of Blitz Web Solution emphasizes forming and distributing valuable, related, steady content to garner consumer response.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-2.png" alt=""></img>
                                                    <h3>Search Engine Marketing</h3>
                                                    <p>Be exposed to the right kind of shoppers with the help of Search engine marketing. SEO marketing of Blitz Web Solution ensures your goods, services, or brand as a whole are visible in SERP.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-3.png" alt=""></img>
                                                    <h3>Social Media Marketing</h3>
                                                    <p>Social media is an unavoidable entity that many brands don't know how to deal with. Blitz Web Solution offers Social Media Marketing that draws the attention of the target audience.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-4.png" alt=""></img>
                                                    <h3>Email Marketing</h3>
                                                    <p>Let buyers have an idea of the benefits after choosing you! Our email marketing keeps the shoppers informed about brands in the form of updates, discounts, new services & advantages.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-5.png" alt=""></img>
                                                    <h3>Video Marketing</h3>
                                                    <p>Want to use visuals to assist the brand? Blitz Web Solution Video marketing empowers interactivity on multiple channels, educates spectators, & lets the brand reach prospective viewers.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/SMM/serv-icon-6.png" alt=""></img>
                                                    <h3>Influencer Marketing</h3>
                                                    <p>Stimulate the audience & transform them into buyers. We employ individuals with expert levels of knowledge vouching for a brand, consequently endorsing & promoting trade or service in an influential manner.</p>
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                              <Tab eventKey="contact" title="ORM" >
                                  <Slider {...digiltaservtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-1.png" alt=""></img>
                                                    <h3>Reputation Research</h3>
                                                    <p>Our effective online reputation management strategies prove to be beneficial for our clients. For getting the best results, market research is the key element we use for communication insights.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-2.png" alt=""></img>
                                                    <h3>Monitor Your Reputation</h3>
                                                    <p>By incorporating online reputation management techniques, we improve your brand's reputation for your customers and partners, making it easier for you to keep your business on track.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-3.png" alt=""></img>
                                                    <h3>Content Creation</h3>
                                                    <p>Blitz Web Solution helps businesses and brands become authoritative figures with appealing and accurate content that complies with the industry's standards and business requirements.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-4.png" alt=""></img>
                                                    <h3>Positive Reputation</h3>
                                                    <p>For a business, it is important to maintain positive reviews so that its target audience acknowledges them. Hence, at Blitz Web Solution, we maintain a positive online reputation for your brand.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-5.png" alt=""></img>
                                                    <h3>24/7 Tracking</h3>
                                                    <p>We ensure tracking 24 hours and seven days a week by utilizing online reputation tools. We help you accomplish positive client reviews and maintain high search engine rankings.</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="digital-serv-box">
                                                    <img src="images/digital-mrkt/ORM/serv-icon-6.png" alt=""></img>
                                                    <h3>Technical & Other Reporting</h3>
                                                    <p>Our ORM and web-related activities help you monitor milestones and the improvement of your project. Let Blitz Web Solution tackle all ORM and web-related activities and give you an exclusive report.</p>
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                          </Tabs>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            
            </>
        );
      }
    }