import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function NonProfit() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='nonprofit-indus-pg'>
        <section className='indusry-main-banner nonprofit-bnr'>
            <video src="images/industry/nonprofit/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>Digital Strategies Shaping<br /> Non-Profit Futures</h2>
                            <p>Incorporating innovative digital strategies is molding the trajectory of non-profit organizations, propelling them toward impactful and sustainable futures. </p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec nonprofit-about-sec'>
        <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Crafting Non-Profit Excellence</h2>
                            <p>Transform your non-profit organization's digital footprint through our professional web design and development services. We're passionate about creating websites that mirror your mission's core. Our team of dedicated experts ensures user-friendly navigation, captivating visuals, and responsive design from inception to launch. As skilled non-profit organization web developers, we comprehend the significance of your work and translate it into a digital masterpiece. Let us empower your noble efforts with a compelling online platform that magnifies your impact and encourages support. Discover how our services can help your cause thrive and make a difference.</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/nonprofit/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec nonprofit-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Marketing for Non-Profit Heroes</h2>
                            <p>Amplify your non-profit organization's reach with our specialized digital marketing services. We're dedicated to tailoring strategies that align with the unique dynamics of the non-profit industry. Our approach focuses on harnessing the power of digital platforms to connect your cause with a broader audience. Through targeted campaigns and impactful storytelling, we elevate your visibility and engagement. With a deep understanding of non-profit organization digital marketing, we're committed to helping you make a lasting impact. Partner with us to navigate the digital landscape effectively, driving support and awareness for your meaningful mission.</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/nonprofit/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry nonprofit-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Revolutionizing Non-Profit<br /> Work with Digital Tools</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry nonprofit-mobile-app-industry'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/nonprofit/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>App Magic for Non-Profits</h2>
                            <p>Empower your non-profit organization with our cutting-edge mobile app development services. We specialize in creating impactful mobile apps that cater to the unique needs of non-profits. Our team crafts user-friendly interfaces and features that enhance engagement and communication. From concept to execution, we ensure your app aligns perfectly with your organization's goals. With extensive expertise in non-profit organization mobile app development, we're dedicated to helping you harness the digital realm to further your mission. Elevate your outreach, foster community, and amplify your impact with a customized mobile app that puts your cause at the fingertips of supporters.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/nonprofit/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec nonprofit-logo-design-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Logo Design</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/nonprofit/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/nonprofit/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/nonprofit/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/nonprofit/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Logo Legends for Non-Profits</h2>
                            <p>Enrich your non-profit's identity with our specialized logo design services tailored to the industry. Our dedicated logo design agency understands the nuances of non-profit organizations, crafting visuals that encapsulate your mission. We believe that a logo is more than just an image – it's a symbol of your purpose. Our non-profit organization logo design services create distinctive and meaningful logos that resonate with your audience. Let us partner with you to visually communicate your values, amplify your presence, and create a lasting impact. Elevate your brand with a logo that captures the heart of your non-profit's cause. </p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                {/* <h4>Expert Designers, Exceptional Results</h4> */}
                                <h2>Benefits of Non-Profit <br /> Web Design</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Amplified Outreach</h4>
                                        <p>Non-profits leverage digital services to reach broader audiences, increasing awareness and support for their vital causes.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Engagement Enhancement</h4>
                                        <p>Digital tools foster meaningful connections, enabling non-profits to engage donors, volunteers, and beneficiaries on a deeper level.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Efficient Operations</h4>
                                        <p>Streamlined processes through digital solutions optimize resource allocation, allowing non-profits to focus more on their missions</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Data-Driven Insights</h4>
                                        <p>Non-profits gain valuable insights from digital analytics, aiding informed decision-making for better strategy alignment and impact measurement.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Global Connection</h4>
                                        <p>Digital services dissolve geographical barriers, connecting non-profits to a worldwide community of supporters and collaborators.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/nonprofit/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Sustainable Growth</h4>
                                        <p>Harnessing digital strategies cultivates long-term sustainability, empowering non-profits to adapt, innovate, and thrive in evolving landscapes</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry nonprofit-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Non-Profit Digital <br />Solutions that Spark Change</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       <IndustryIconSec />

        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
