import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';


export default function Automotive() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='automotive-indus-pg'>
        <section className='indusry-main-banner automotive-bnr'>
            <video src="images/industry/automotive/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>The Fusion of Automotive <br />and Digital Innovation</h2>
                            <p>Increase your automotive business’s online presence with top of the line automotive digital solutions.</p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec automotive-about-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Rev Up Your Online Presence </h2>
                            <p>Unlock the road to success with our expert automotive web design and development services. Your journey to a captivating online presence begins here. Our professional automotive web developers craft dynamic websites that not only showcase your brand's essence but also engage visitors with seamless functionality. Elevate your business with bespoke automotive website development, where innovation meets user experience. From concept to execution, we're your driving force, turning visions into captivating reality. Partner with us for top-tier automotive web development services that steer you ahead of the competition. Your digital showroom awaits – let's accelerate your online impact today!</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/automotive/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec automotive-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Ignite with <br />Automotive Digital Marketing!</h2>
                            <p>Rev up your automotive brand's online presence with our specialized digital marketing services tailored to the industry. Navigate the digital landscape with confidence as we steer your brand to success. Our automotive digital marketing experts understand the nuances of the industry, crafting strategies that accelerate your reach and impact. From SEO-optimized content to targeted campaigns, we fuel your growth in the digital realm. Drive engagement, boost leads, and shift into high gear with our proven track record of elevating automotive businesses. Your success story in the digital world begins with our strategic automotive industry digital marketing solutions.</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/automotive/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry automotive-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Revolutionize Your Ride with Digital Solutions</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry automotive-mobile-app-industry'>
        <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/automotive/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>Cruise with <br />Automotive App Pros!</h2>
                            <p>Experience automotive innovation at your fingertips with our cutting-edge mobile app services. We specialize in crafting bespoke solutions for the automotive industry that redefine convenience and connectivity. Our skilled team of developers brings your visions to life, creating intuitive automotive mobile apps that cater to modern drivers' needs. Seamlessly integrate navigation, vehicle insights, and personalized experiences into a single app, enhancing every journey. From concept to deployment, our expertise in automotive mobile app development ensures a smooth ride towards a digitally empowered driving experience. Embrace the future of mobility with our transformative automotive mobile app services.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/automotive/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec automotive-logo-design-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Logo Design</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/automotive/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/automotive/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/automotive/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/automotive/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Logo Shift into High Gear</h2>
                            <p>Make your automotive brand stand out with our expert logo design services. As a specialized automotive industry logo design agency, we understand the unique essence of your business. Our creative team crafts captivating logos that encapsulate your brand's identity and values. With a keen eye for detail, we fuse automotive elements seamlessly to create iconic logos that leave a lasting impression. From sleek modern designs to classic emblems, our automotive logo design services cater to your specific vision. Drive your brand forward with a distinctive logo that speaks volumes about your automotive business.</p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                {/* <h4>Expert Designers, Exceptional Results</h4> */}
                                <h2>Benefits of Automotive <br />Web Design</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Enhanced Customer Engagement</h4>
                                        <p>Automotive digital services empower you to connect with your customers on a deeper level. From interactive websites to engaging social media campaigns, you can create meaningful interactions that build brand loyalty and trust. </p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Wider Reach</h4>
                                        <p>Going digital means breaking geographical barriers. Your automotive business can now reach a global audience, expanding your customer base and market presence beyond traditional boundaries.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Data-Driven Insights</h4>
                                        <p>With digital tools, you gain valuable insights into customer behavior, preferences, and market trends. This data allows you to make informed decisions, tailor your offerings, and optimize your strategies for maximum impact.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Efficient Marketing</h4>
                                        <p>Digital marketing lets you target specific demographics, ensuring your efforts are directed towards those most likely to convert. This precision saves time, effort, and resources, resulting in a higher return on investment.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Streamlined Operations</h4>
                                        <p>From appointment scheduling to inventory management, automotive digital solutions automate and streamline various processes, boosting operational efficiency and providing a smoother customer experience.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/automotive/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Constant Connectivity</h4>
                                        <p>Through mobile apps and responsive websites, your customers can stay connected with your services 24/7. This accessibility enhances customer satisfaction and helps you remain competitive in the fast-paced automotive landscape.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry automotive-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Digital Acceleration <br />for Automotive Enthusiasts </h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='what-sec-inner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>What’re You Looking For?</h2>
                            <p>We exude passion in each project we take on. Our process is simple and the result of coordination, <br />creative genius and professionalism of our creatives.</p>
                        </div>
                    </div>
                    <div className='col-sm-12 p0 what-main-col-inr'>
                        <div className='what-main-list-dv'>
                            <ul>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-1.png' alt=''></img>
                                        <h4>Children</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-2.png' alt=''></img>
                                        <h4>Fashion</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-3.png' alt=''></img>
                                        <h4>Architecture</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-4.png' alt=''></img>
                                        <h4>Food</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-5.png' alt=''></img>
                                        <h4>Animals</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-6.png' alt=''></img>
                                        <h4>Line Design</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-7.png' alt=''></img>
                                        <h4>Portraits</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-8.png' alt=''></img>
                                        <h4>Fantasy</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-9.png' alt=''></img>
                                        <h4>Retro</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-10.png' alt=''></img>
                                        <h4>People</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-11.png' alt=''></img>
                                        <h4>Animation</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-12.png' alt=''></img>
                                        <h4>Beauty</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-13.png' alt=''></img>
                                        <h4>Black And White</h4>
                                    </div>
                                </li>
                                <li>
                                    <div className='what-bx-inr'>
                                        <img src='images/what-sec/what-icon-14.png' alt=''></img>
                                        <h4>Business</h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
