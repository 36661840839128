import React, { useEffect} from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import PricingTab from '../components/includes/PricingTab';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function Pricing() {

    document.title ="Affordable Pricing & Packages - Blitz Web Solution "; 



    useEffect(() => {
        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>
    
    <section className='inner-banner about-banner pricing-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                        <h2>Our Pricing Packages</h2>
                        <p>Our custom website designers are here to boost your business through impeccable website designs. <br />We improve your conversion rates and sales and revenue.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='pricing-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Affordability With Quality</h2>
                        <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 pricing-main-col'>
                    {/* <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div> */}

                    <PricingTab />
                </div>
            </div>
        </div>
    </section>


    <IndustryIconSec />

    {/* <section className='process-inner-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Fill Out A Brief</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Tell Us More</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Design Creation</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Final Delivery</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>How We Create <br />Outstanding Logos</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <TestiSec />
    <Popup />
    
    </>
  )
}
