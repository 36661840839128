import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import PortfolioMainTab from '../components/includes/PortfolioMainTab';
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function Portfolio() {

    document.title ="Have A Look at Our Portfolio - Blitz Web Solution"; 


    useEffect(() => {
        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });

  return (
    <>
        <section className='inner-banner about-banner portfolio-banner'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='inr-bnr-txt '>
                            <h2>Our Portfolio</h2>
                            <p>Our custom website designers are here to boost your business through impeccable website designs.<br /> We improve your conversion rates and sales and revenue.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className='portfolio-sec'>
            <div className='container'>
                <div className='row'>
                    <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                    <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                    <img className='port-af-img' src='images/port-af.png' alt=''></img>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h4>A Glimpse Of Work!</h4>
                            <h2>Our Latest Creative Work</h2>
                        </div>
                    </div>
                    <div className='col-sm-12 p0 main-port-div'>
                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-1.png' alt=''></img>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-2.png' alt=''></img>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-3.png' alt=''></img>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-4.png' alt=''></img>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-5.png' alt=''></img>
                            </div>
                        </div>

                        <div className='col-sm-4'>
                            <div className='port-bx'>
                                <img src='images/port-6.png' alt=''></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <PortfolioMainTab />
    

    <section className='why-choose-sec-inner'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>Trusted expertise, exceptional quality, and unrivaled customer satisfaction—our commitment to excellence sets us apart in delivering unparalleled solutions.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-1.png' alt='' />
                                <h3>Unparalleled<br /> Expertise</h3>
                                <p>Our team of seasoned professionals delivers unmatched knowledge and industry experience.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-2.png' alt='' />
                                <h3>Outstanding<br /> Quality</h3>
                                <p>We pride ourselves on delivering exceptional work that exceeds expectations.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-3.png' alt='' />
                                <h3>Client-Centric<br /> Approach</h3>
                                <p>We prioritize your needs, ensuring personalized solutions tailored to your requirements.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-4.png' alt='' />
                                <h3>Timely<br /> Delivery</h3>
                                <p>Count on us for prompt, reliable, and on-time completion of projects.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-5.png' alt='' />
                                <h3>Competitive<br /> Pricing</h3>
                                <p>We offer competitive rates without compromising on the quality of our services.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/contact/help-icon-6.png' alt='' />
                                <h3>Customer <br />Satisfaction</h3>
                                <p>Our commitment to your satisfaction drives us to go the extra mile, every time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

   <IndustryIconSec />

    {/* <section className='process-inner-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Fill Out A Brief</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Tell Us More</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Design Creation</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Final Delivery</h4>
                                    <p>We begin by brainstorming ideas and outlining an initial draft. Then, we move towards plot scheming</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>How We Create <br />Outstanding Logos</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <TestiSec />
    <Popup />
    </>
  )
}
