import React, { useEffect} from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import MobileServicesSlider from '../components/includes/MobileServicesSlider';
import MobilePortfolioSec from '../components/includes/MobilePortfolioSec';
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function MobileApp() {

    document.title ="Custom Mobile App Development & Design Services In London "; 
	document.getElementsByTagName("META")[2].content="Get your app designed and developed with the professional mobile app developer at Blitz Web Solution. For further details, call us at 207 072 7323 ";


    useEffect(() => {

       


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>
    <section className='inner-banner ecommerce-banner mobile-app-bnr'>
        <video src="images/mobile/banner.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='inr-bnr-txt '>
                        <h2>Custom Mobile Application Development Company</h2>
                        <p>Blitz Web Solution is a versatile mobile app development agency that works tirelessly to bring out the best in your business through mobile app development services. </p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>

                </div>
            </div>
        </div>
    </section>
    <section className='about-mobile-sec'>
        {/* <h2 className="mobile-outline-txt-st outline-txt-services">APPLICATION</h2> */}
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='about-mobile-img'>
                        <img src='images/mobile/about-side-img.png' alt=''></img>
                        <span className='circle-mobile-app'></span>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='about-txt hd-txt about-mob-txt'>
                        <h4>Best Mobile App Developers</h4>
                        <h2>Hire Mobile App Developers To Digitize Your Business</h2>
                        <p>If you are looking for affordable mobile app developers, then you have landed just in the right place, as Blitz Web Solution is one of the best mobile application development companies. In the modern era, you just cannot rely on a website as you need a mobile application that is available in every pocket and is easily accessible. Our mobile app developers are experts in developing every kind of application with an exceptional user interface that not only looks good but functions extraordinarily. Our team is perfect for developing mobile apps that can take your business to new heights. </p>
                        <ul>
                            <li><img src='images/mobile/check-icon.png' alt='' /> Experienced App Developers </li>
                            <li><img src='images/mobile/check-icon.png' alt='' /> Cross-platform Applications</li>
                            <li><img src='images/mobile/check-icon.png' alt='' /> Apps for businesses of all sizes</li>
                            <li><img src='images/mobile/check-icon.png' alt='' /> Affordable mobile app solutions</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='mobile-services'>
         <h2 className="mobile-outline-txt-st outline-txt-services">Services</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className='col-sm-5'>
                    <div className='hd-txt mob-serv-txt'>
                        <h2>Explore Our Mobile App Development Services For All</h2>
                        <p>Being the best mobile app development company in the UK, we are bound to provide services that cover the problems for everyone. Our robust mobile app development services include various types of mobile applications that can perform equally well on every platform. We have not specified our mobile application development to only one technology as we are a mobile app developers company that cares for all our customers. Look at what we have to offer and choose whichever service suits you best. </p>
                    </div>
                </div>
                <div className='col-sm-7'>
                    <div className='mobile-serv-slider-main-dv'>
                        <img className='mobile-mock-up-serv' src='images/mobile/services-mockup-img.png' alt=''/>
                        <MobileServicesSlider />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='cta-sec cta-web-sec cta-mobile-pg-sec'>
        <div className='container'>
            <div className='row'>                        
                <div className='col-sm-6'>
                    <div className='cta-side-img cta-side-img-web cta-side-img-mobile'>
                            <img src='images/mobile/cta-side-img.png' alt=''></img> 
                        {/* <video src="images/website-design/cta-side-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video> */}
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='cta-txt web-cta-txt hd-txt'>
                        <h2>Unlock Your Mobile App Potential Today</h2>
                    </div>
                    <div className='bnr-btn about-btn'>
                        <a href='#.' className='get-btn popup-btn'>Get Started</a>
                        <a href='#.' className='chat-btn chats'>Live Chat</a>
                    </div>
                    <div className='cta-tel'>
                        <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                        <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='mobile-app-devlopment-sec'>
        <div className='container'>
        <video className='mobile-app-dev-vid' src="images/mobile/services-video.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
            <div className='row'>
                <div className='col-sm-6'>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>Comprehensive Mobile App Design Services </h2>
                        <p>We provide unmatchable mobile app designs that are not just user-friendly but are highly functional. Our designs are unique and quite different from what the industry is offering because we have a team that outsmarts everyone.</p>
                        <ul className='mob-app-dev-list'>
                            <li className='mob-app-li-st'>
                                <div className='mob-app-dev-li-bx'>
                                    <img src='images/mobile/services-icon-1.png' alt=''></img>
                                    <div className='mob-app-dev-txt'>
                                        <h3>Android Applications</h3>
                                        <p>We provide android application designs that can be highly effective and simultaneously look very attractive. </p>
                                    </div>
                                </div>
                            </li>
                            <li className='mob-app-li-st'>
                                <div className='mob-app-dev-li-bx'>
                                    <img src='images/mobile/services-icon-2.png' alt=''></img>
                                    <div className='mob-app-dev-txt'>
                                        <h3>iOS Applications</h3>
                                        <p>We design iOS applications that function according to the iOS needs and work perfectly on all iOS devices.</p>
                                    </div>
                                </div>
                            </li>
                            <li className='mob-app-li-st'>
                                <div className='mob-app-dev-li-bx'>
                                    <img src='images/mobile/services-icon-3.png' alt=''></img>
                                    <div className='mob-app-dev-txt'>
                                        <h3>Cross-platform Applications</h3>
                                        <p>Being the experts on iOS and Android, we can design and develop apps that can work on every platform without any troubles. </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='portfolio-sec mobile-port-folio-sec'>
        <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
        <div className='container'>
            <div className='row'>
                <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                <img className='port-af-img' src='images/port-af.png' alt=''></img>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>A Glimpse Of Work!</h4>
                        <h2>Our Latest Creative Work</h2>
                    </div>
                </div>
                <div className='col-sm-12 p0 mobile-main-port-div'>
                    <img className='mobile-mock-portf' src='images/mobile/port/our-11.png' alt='' />
                    <MobilePortfolioSec />
                </div>
            </div>
        </div>
    </section>
    <section className='why-choose-sec-inner'>
         <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>Blitz Web Solution is a custom mobile app development company that doesn’t compromise on our services and provides unmatchable solutions for all your mobile application needs.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/mobile/help-icon-1.png' alt='' />
                                <h3>Expert Team of Designers</h3>
                                <p>Our mobile app design services are backed by a team of highly skilled and experienced designers who have a deep understanding of user-centric design principles and deliver them according to your needs. </p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/mobile/help-icon-2.png' alt='' />
                                <h3>User-Centered Approach</h3>
                                <p>We prioritize your users' needs and preferences throughout the design process. By conducting thorough user research and UKbility testing, we ensure that the app design is near to perfection.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/mobile/help-icon-3.png' alt='' />
                                <h3>Tailored Solutions </h3>
                                <p>We understand that every app is unique and has distinct requirements. Our team crafts custom design solutions that align with your brand identity, business goals, and specific app functionalities. </p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/mobile/help-icon-4.png' alt='' />
                                <h3>Timely Delivery and Cost-Effectiveness</h3>
                                <p>We understand the importance of timely project delivery. Our streamlined design process, combined with efficient project management, ensures that we meet deadlines without compromising on quality. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='process-inner-sec'>
        <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Discovery and Planning</h4>
                                    <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Design and Prototyping</h4>
                                    <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Development and Testing</h4>
                                    <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Deployment and Maintenance</h4>
                                    <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>Our Mobile App Development Process</h2>
                        <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <IndustryIconSec />

    <TestiSec />
    <Popup />
    </>
  )
}
