import React, { Component } from "react";
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Popup from "./Popup";

export default class PricingTab extends Component {
    render() {
        const pricingtab = {
            dots: true,
            arrows:false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <>
            <section className='digtal-mrkt-service-sec portfolio-main-pg-sec'>
                <div className='container'>
                    <div className='row'>
                    <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                    <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                    <img className='port-af-img' src='images/port-af.png' alt=''></img>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            
                        </div>
                    </div>
                        <div className='col-sm-12'>
                           
                            <Tabs
                              defaultActiveKey="website"
                              transition={false}
                              id="noanim-tab-example"
                              className="mb-3"
                                unmountOnExit={true}
                                mountOnEnter={true}
                            >
                                <Tab eventKey="website" title="Website">
                                  <Slider {...pricingtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Startup Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>149</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 3 Page Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 5 Stock Images (Industry Specific)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Contact Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 3 Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee *</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive for $125</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> CMS for Additional $150</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Professional Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>594</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 10 Unique Pages Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Contact us Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Appointment Scheduling CTA Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 10 Stock images (Industry Specific)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 5 Custom Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider Banner</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Google Friendly Sitemap</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive for Additional $149</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Elite Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>994</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 15 Custom Pages Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Conceptual and Dynamic Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Striking Hover Effects</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Reservation/Appointment Tool (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Payment Gateway Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Contact Forms</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Lead Capturing Forms &amp; CTA</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Newsletter Subscription CTA (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Newsfeed Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Social Media Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images (Industry Specified)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Unique Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Add on</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> On Page &amp; Technical SEO for $225</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Business Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>1524</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Up to 25 Custom Pages Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Interactive, Dynamic &amp; High End Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom WP (OR) Custom PHP Development</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> CMS (Content Management System)/Admin Panel</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Special Striking Hover Effects</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Liquid Responsive Layout </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multi Lingual (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Reservation/Appointment Tool (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Payment Gateway Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Book A Call CTA Form</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Dynamic CTA Forms</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Lead Capture CTA Forms</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Bar </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> User Login </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple Custom Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images (Industry Specific)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Live Feeds of Social Networks integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Installation</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Industry Specified Team of Expert Designers and Developers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Ownership Rights</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Add on</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> On Page &amp; Technical SEO for $225</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Business Pro Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>2594</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Pages Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom 2D Explainer Video Animation </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Voice Over &amp; Sound Effects</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Professional Script Writing</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Storyboard Creation</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UI / UX Interactive &amp; Dynamic HD Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> SEO Meta Tags</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Liquid Responsive </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom WP (or) Custom PHP Development</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider Banner</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Made Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple Stock Images</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Special Hoover Effects</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Payment Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multi Lingual (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Dynamic Forms (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Bar</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Live Feeds of Social Networks integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Image alt text</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> SEO Internal Linking</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Industry Specified Team of Expert Designers and Developers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee </li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE On Page &amp; Technical SEO</li>
                                                        
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Customized Web Portal Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>4494</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                    <ul className=" jack jack2">
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Complete Custom Design &amp; Development</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Dating Portal, Job Portal, Professional Network Portal, Social Network Portal, Restaurant Portal, Medical Portal, Enterprise Portal (Any One)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Unique, User Friendly, Interactive, Dynamic, High End UI Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Interactive Sliding Banners</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Special Hoover Effects</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Stock Images</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  User Signup Area</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Client/User Dashboard Area</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Custom Coding and Development</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Online Payment Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Multi Lingual (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Custom Dynamic Forms (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Shopping Cart Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Complete Database Creation</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Signup Automated Email Authentication</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Web Traffic Analytics Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  3rd Party API Integrations</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Signup Area (For Newsletters, Offers etc.)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Search Bar for Easy Search</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Live Feeds of Social Networks integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Search Engine Submission</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Module-wise Architecture</li>       
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Aware Winning Team of Expert Designers and Developers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>   Value Added Services</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Complete Source Files</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Dedicated Project Manager</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  100% Ownership Rights</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  100% Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  *NO MONTHLY OR ANY HIDDEN FEE*</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Content Management System (Custom)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Extensive Admin Pane</li>                                         
                                                    </ul>
                                                        
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                              <Tab eventKey="logo" title="Logo">
                                  <Slider {...pricingtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Basic Logo Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>35</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 3 Custom Logo Design Concepts</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 1 Dedicated Designer</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 4 Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 2 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee *</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Startup Logo Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>65</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 4 Custom Logo Design Concepts</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> By 2 Designers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee*</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Professional Logo Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>99</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Logo Design Concepts</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> By 4 Industry Based Designers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE File Formats (EPS, Ai, GIF, JPEG, PSD)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Business Logo Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>149</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Logo Design Concepts</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> By 8 Award Winning Designers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Free Icon Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope, Invoice)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Double Side Flyer (OR) Bi-Fold Brochure</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Email Signature Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Logo & Web Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>249</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Logo Design Concepts</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> By 8 Award Winning Designers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Icon Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 2 Stationary Design Sets (Business Card, Letterhead, Envelope)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE MS Word Letterhead</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Free Email Signature</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 3 Page Custom Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 2 Stock Photos</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 2 Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        {/* </div> */}
                                  </Slider>
                              </Tab>
                              
                              <Tab eventKey="ecommerce" title="Ecommerce">
                                  <Slider {...pricingtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Beginners E-Commerce Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>789</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Up-to 10 Unique Pages Website</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Up-to 100 Products</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Mini Shopping Cart Integration</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Payment Module Integration</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Corporate E-Commerce Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>1589</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Up-to 15 Unique Pages Website</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Up-to 500 Products</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Conceptual and Dynamic Website</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Easy Product Search</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Product Reviews</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Full Shopping Cart Integration</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Payment Module Integration</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Sales &amp; Inventory Management</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Jquery Slider</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Custom Email Addresses</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Elite E-Commerce Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>2699</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Pages Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> E-Commerce Store Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Product Detail Page Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Products</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Categories</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Product Rating &amp; Reviews</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Banner Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Featured Products Showcase</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Full Shopping Cart Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Multi-currency Support</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Online Payment Module Setup</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Facebook Shop Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Sales &amp; Inventory Management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Easy Product Search</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Payment Gateway Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Content Management System/CMS</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Customer Log-in Area</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Social Media Plugins Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Tell a Friend Feature</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Social Media Pages</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Value Added Services</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Logo Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Icon Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Print Media</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Stationary Design (Business Card, Letterhead &amp; Envelope Design)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Invoice Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Bi-Fold Brochure (OR) 2-Sided Flyer Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Product Catalog Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Sign age Design (OR) Label Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE T-Shirt Design (OR) Car Wrap Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Email Signature Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> FREE Facebook, Twitter, YouTube, Google+ &amp; Pinterest Page Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Dedicated Account Manager</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> All Final File Formats</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Advanced E-Commerce Package</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>4999</h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Page Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited Page Website</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Content Management System (CMS)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Unique Pages and UI Design</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Custom Development</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Process Automation Tools</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Newsfeed Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Social Media Plugins Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Upto 40 Stock images</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 10 Unique Banner Designs</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> JQuery Slider</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Custom Email Addresses</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Social Media Page Designs (Facebook, Twitter, Instagram)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Inventory/Shipping/Supplier Module:</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Manage thousands to millions of inventory with ease and check stock levels in real-time. Receive low inventory notifications and generate purchase orders to replenish your stock.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Suppliers Integration (API NEEDED)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Shipper Integration (API NEEDED)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Order management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> LOT numbers and expire date tracking</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Transfer stock between warehouses (If Warehouse - API NEEDED)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Receive stock into a specific warehouse (If Warehouse - API NEEDED)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Fulfill orders from a particular warehouse (If Warehouse - API NEEDED)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Stock Management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Actionable Insights</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Real- Time Visibility</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Inventory Opportunities</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Advanced Features: (API Needed For Suppliers/Warehouse)</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Speak to suppliers during trivial conversations.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Set and send actions to suppliers regarding governance and compliance materials. Place purchasing requests.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Research and answer internal questions regarding procurement functionalities or a supplier/supplier set.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Receiving/filing/documentation of invoices and payments/order requests</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Machine Learning (ML) for Supply Chain Planning (SCP)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Machine Learning for Warehouse Management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Natural Language Processing (NLP) for Data Cleansing and Building Data Robustness</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Invoices &amp; Estimates</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Create beautiful, professional invoices &amp; estimates in just a few seconds and then instantly email them as PDF's directly to your customers or prospects.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Automated Split invoicing</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Automated Combine invoices</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Invoice templates</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Barcode Scanning</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Scan inventory into your orders, generate barcodes for your documents, and search for inventory or documents by scanning barcodes.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Locations and Zones</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Have multiple warehouses, offices, or retail stores? No problem. Easily track where all your inventory is by organizing everything into locations and zones. Organize inventory items using custom attributes such as size, color, and location. View how many you have globally or at each location.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Customer Accounts</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Performance and analytics</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Customization of Personal Details</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Process management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Sales Automation</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Team Collaboration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Marketing Automation</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>  Security</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Integrations</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Mobile Notifications</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Sales Reports</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Trend Analytics</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Forecasting</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Territory Management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Account Management</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Event Integration</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Advanced Data Security</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Purchase Orders</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> With integrated purchase orders, you can easily replenish your inventory levels by ordering more stock and even track when those new items will arrive.</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Partial orders fulfill</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Backordering</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> <strong>Financial Reports</strong></li>
                                                        <li><i className="fa-solid fa-arrow-right"></i> Generate extremely detailed reports for your inventory, sales and services. Filter your reports by date-range and category to see what's making you the most money.</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                              <Tab eventKey="digital" title="Digital Marketing">
                                <Tabs
                                    defaultActiveKey="seo"
                                    id="noanim-tab-example"
                                    className="mb-3"
                                    unmountOnExit={true}
                                    mountOnEnter={true}
                                    transition={false}
                                    >
                                        <Tab eventKey="seo" title="SEO">
                                            <Slider {...pricingtab}>
                                                    {/* <div className='testi-slider'> */}
                                                        <div>
                                                            <div className='pricing-box'>
                                                                <h3>Basic Seo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>599</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Campaign Setup (Min 6 Months) </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> In-depth Site Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Duplicacy Check </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Initial Backlinks analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Competition Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keyword Research </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keywords plan 30 </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>On-Page Optimization  </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Title &amp; Meta Tags Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Optimization   </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Page Speed Analysis &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> HTML Code Cleanup &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Internal Link Structuring &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Robots.txt Creation/Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google &amp; Bing Webmaster Tools Setup</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Setup &amp; Integration</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Content and Link Acquisition (Per Month)</b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Blog Writing - 2 </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Guest Blog Outreach -1</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> DOC / PDF Submission </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Infographic Creation &amp; Distribution (1 every 3rd month</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Local Search Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Citation Building</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Classified Submissions </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Monthly Reporting </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Search Engine Rank Report</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> SEC Reports  </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Monthly Action Plan </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>£ Per Month  </b></li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='pricing-box'>
                                                                <h3>Plus Seo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>699</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Campaign Setup (Min 6 Months) </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> In-depth Site Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Duplicacy Check </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Initial Backlinks analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Competition Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keyword Research</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keywords plan 45</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>On-Page Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Title &amp; Meta Tags Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i>  Content Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Page Speed Analysis &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> HTML Code Cleanup &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Internal Link Structuring &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Robots.txt Creation/Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google &amp; Bing Webmaster Tools Setup</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Setup &amp; Integration</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Content and Link Acquisition (Per Month)</b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Blog Writing</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Guest Blog Outreach - 2</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> DOC / PDF Submission </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Infographic Creation &amp; Distribution (1 every 3rd month) </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Local Search Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Citation Building </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Classified Submissions </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Monthly Reporting </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Search Engine Rank Report</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> SEO Reports</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i>  Monthly Action Plan </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>£ Per Month </b></li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='pricing-box pricing-extra-rem'>
                                                                <h3>Professional Logo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>99</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Logo Design Concepts</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> By 4 Industry Based Designers</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope)</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE File Formats (EPS, Ai, GIF, JPEG, PSD)</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        <Tab eventKey="smm" title="SMM">
                                            <Slider {...pricingtab}>
                                                {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Basic Smm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>600</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  2 Social Media Channels (Facebook / Instagram) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Social media account setup </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Complete Account Management </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  3 Posts per week (per network) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Custom Editorial calendar (Review before publishing) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Dedicated account manager </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>1200</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 4 Social Media Channels (Facebook / Twitter / Pinterest / Instagram)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Social media account setup</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Complete Account Management 3 Posts per week (per network)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Custom Editorial calendar (Review before publishing)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Call to Action Integration </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> End of Term Report </li>z
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Account Manager </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus Smm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>2000</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 6 Social Media Channels (Facebook / Twitter / Pinterest/ Instagram / Youtube / LinkedIn)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Social media account setup</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Complete Account Management</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 4 Posts per week (per network)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 GIF post per Month </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Custom Editorial calendar (Review before publishing)(Facebook)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Call to Action Integration</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 Stock Video Cover Banner</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Monthly Analytic Report</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Direct Access To Social Media Team</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        
                                        <Tab eventKey="orm" title="ORM">
                                            <Slider {...pricingtab}>
                                                {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Startup Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>150</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 Month Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 3</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Basic Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>349</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 3</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
										
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>599</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i> Social Bookmarking - 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 15</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                               
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Premium Orm Packagee</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>899</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -3</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 20</li>
															<li><i className="fa-solid fa-arrow-right"></i> Article writing &amp; submissions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i> Social Bookmarking - 15</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 20</li>
															<li><i className="fa-solid fa-arrow-right"></i> Video making &amp; sharing</li>
															<li><i className="fa-solid fa-arrow-right"></i> Infographics</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Business Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>1199</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i>Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i>Accounts Setup (Trustpilot, Bark, Sitejabber) - 5</li>
															<li><i className="fa-solid fa-arrow-right"></i>Keywords / Phrases -5</li>
															<li><i className="fa-solid fa-arrow-right"></i>Search positions monitored and protected - top 20</li>
															<li><i className="fa-solid fa-arrow-right"></i>Article writing &amp; submissions</li>
															<li><i className="fa-solid fa-arrow-right"></i>Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i>Social Bookmarking - 20</li>
															<li><i className="fa-solid fa-arrow-right"></i>Blog setup (Wordpress, Blogspot, Tumblr etc.) - 3</li>
															<li><i className="fa-solid fa-arrow-right"></i>Blog Posts - 6</li>
															<li><i className="fa-solid fa-arrow-right"></i>Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i>Reviews Writing &amp; Posting - 25</li>
															<li><i className="fa-solid fa-arrow-right"></i>Video making &amp; sharing</li>
															<li><i className="fa-solid fa-arrow-right"></i>Infographics</li>
															<li><i className="fa-solid fa-arrow-right"></i>Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        
                                        
                                        
                                    </Tabs>                                  
                              </Tab>
                              <Tab eventKey="webcontent" title="Web Content">
                                  <Slider {...pricingtab}>
                                        {/* <div className='testi-slider'> */}
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Articles/Blogs</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>20<span><sub>/</sub>Per Page</span></h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i>300-400 Words per Page</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Timely Delivery</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Superior Standard Content</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Professional Writers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*SEO Optimized (If Required)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*Bulk Order Discounts Available</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Website Pages</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>50<span><sub>/</sub>Per Page</span></h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i>300-400 Words per Page</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Timely Delivery</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Superior Standard Content</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Professional Writers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*SEO Optimized (If Required)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*Bulk Order Discounts Available</li>
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='pricing-box'>
                                                    <h3>Product Descriptions</h3>
                                                    <div className='amout-bx'>
                                                        <h2><sup>£</sup>10<span><sub>/</sub>Per Description</span></h2>
                                                    </div>
                                                    {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                    <ul>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Upto 100 Words per Product</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Timely Delivery</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Superior Standard Content</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>Professional Writers</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>100% Satisfaction Guarantee</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*SEO Optimized (If Required)</li>
                                                        <li><i className="fa-solid fa-arrow-right"></i>*Bulk Order Discounts Available</li>
                                                       
                                                    </ul>
                                                    <div className='bnr-btn pricing-btn'>
                                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>

                             
                          </Tabs>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            <Popup />
            </>
        );
      }
    }