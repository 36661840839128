import React, { useEffect } from 'react';

export default function Popup() {
    useEffect(() => {
         
             window.jQuery( ".txtOnly" ).keypress(function(e) {
            var key = e.keyCode;
            if (key >= 48 && key <= 57) {
                e.preventDefault();
            }
        });
        window.jQuery('.popup-btn').on('click', function(){
            window.jQuery('.popupform-main').addClass('active');
            window.jQuery('body').addClass('o-hidden');
            window.jQuery('.overlay-bg').fadeIn(500);
            window.jQuery('.close-btn').on('click', function(){
            window.jQuery('.popupform-main').removeClass('active');
            window.jQuery('body').removeClass('o-hidden');
            window.jQuery('.overlay-bg').fadeOut(500);
           });
            window.jQuery('.overlay-bg').click(function() {
            window.jQuery('popupform-main').removeClass('active');
            window.jQuery('body').removeClass('o-hidden');
            window.jQuery('.overlay-bg').fadeOut(500);
           
           });
           });



    });
  return (
    <>
    {/* <div className="overlay-bg"></div> */}
    <div  className="popupform-main" id='popup_form_main'>
        <div className="pop-form ta-center">
            <div id="pop-form" className="col-sm-12">
                <img className="popup-discount" src="images/popup/discount-tag.png" alt='' />
                {/* <img className="pop-tag-st-inden" src="popform-tag-st.png" alt='' />  */}
                <div className="col-sm-6">
                    <div className="popup-side-img">
                        
                        
                        <img className="popup-img3-st" src="images/popup/popup-img-side.png" alt='' />
                       
                    </div>
                </div>
                <div className="col-sm-6">
                    <h2><span>Fill This Form To</span>  Avail Special Discounts</h2>
                    <a href="#." className="close-btn ta-center">X</a>
                    <div className="clearfix"></div>
                    <form action="https://www.blitzwebsolution.co.uk/mail/" className="jform validate main-pop" method="POST">
                        <div className="col-md-12">
                            <div className="control-group">
                                <i className="fa fa-user" aria-hidden="true"></i>
                                <input className='txtOnly'  type="text" name="cn" placeholder="Enter Name" required />
                    
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="control-group">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <input type="email" name="em" className="required email" placeholder="Enter your email here" required="" />
                            </div>
                        </div>
                        <div className="col-md-12 phonecode">
                            <div className="control-group clearfix">
                                <div className="numberarea">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <input onInput={(e) => {if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0,e.target.maxLength);}} type = "number"    maxLength = {12}  name="pn" placeholder="Enter Phone" required />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 ">
                            <div className="control-group clearfix ">
                                <div className="textarea_auto">
                                    <i className="fa fa-comments" aria-hidden="true"></i>
                                    <textarea className="form-control" name="msg" placeholder="Talk about your project" required></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12">
                            <div className="control-group clearfix  submit-btn-pop-st">
                                <input type="submit" className="btn btn-default pop_btn submit-btn fspx-23  ls-medium d-block w-100 fw-bold" value="Submit" />
                                <input type="hidden" id="lead_area_popup" name="lead_area" value="for £119" />
                                <input type="hidden" id="lead_org_price" name="lead_org_price" value="19" />
                                <input type="hidden" name="send" value="1" />
                                <input type="hidden" name="service_id" value="5669"   className="service_id" />
                                
                                <input type="hidden" name="lb_source" value="" />
                                <input type="hidden" name="lb_source_cat" value="" />
                                <input type="hidden" name="lb_source_nam" value="" />
                                <input type="hidden" name="lb_source_ema" value="" />
                                <input type="hidden" name="lb_source_con" value="" />
                                <input type="hidden" name="lb_source_pho" value="" />
                                <input type="hidden" name="lb_source_off" value="" />
                                
                                <input type="hidden" name="fullpageurl" value="" />
                                <input type="hidden" name="pageurl" value="" />
                                
                                <input type="hidden" name="ip2loc_ip" value="" />
                                <input type="hidden" name="ip2loc_isp" value="" />
                                <input type="hidden" name="ip2loc_org" value="" />
                                <input type="hidden" name="ip2loc_country" value="" />
                                <input type="hidden" name="ip2loc_region" value="" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

