import React, { useEffect } from 'react';
import WebServSlider from '../components/includes/WebServSlider';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import { LightgalleryItem } from "react-lightgallery";
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function EcommerceDesign() {
    document.title ="Custom E-Commerce Web Development & Design Services In CA "; 
	document.getElementsByTagName("META")[2].content="Get your e-commerce website designed and developed by Blitz Web Solution. We offer e-commerce web design services and web development services all around US.";
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
   <>
   <section className='inner-banner ecommerce-banner'>
        <video src="images/ecommerce/banner.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='inr-bnr-txt '>
                        <h2>Building Brands, <br />One Click At a Time</h2>
                        <p>Our custom eCommerce web development services are designed to empower your business through strategic eCommerce website designing solutions. eCommerce website designers at Blitz Web Solutions are highly skilled individuals who are bound to produce compelling designs to take your business to the next level.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>

                </div>
            </div>
        </div>
    </section>
    <section className='about-ecom-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Ecommerce</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>Creating eCommerce Magic</h4>
                        <h2>Attract Customers With User-Friendly eCommerce Websites</h2>
                        <p>Blitz Web Solutions is a leading eCommerce website development company that offers competent services for e-commerce web designs. Our goal is to build your name on different platforms through a custom eCommerce website. We are obliged to provide you with satisfying e-commerce web development services. Our professional e-commerce website developers create a unique brand identity by using a tactical approach. eCommerce website design and development cover all the activities intended for the formation, maintenance, and development of an e-commerce website design. </p>
                    </div>
                    <div className='about-ecom-side-img'>
                        <img src='images/ecommerce/about-side-img.png' alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='our-servcies-sec-web'>
    <h2 className="mobile-outline-txt-st outline-txt-services">Services</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className='col-sm-12 p0 serv-main-web-col row'>
                    <div className='col-sm-4'>
                        <div className='serv-web-txt hd-txt'>
                            <h2>What We <br />Are Offering?</h2>
                            <p>Blitz Web Solutions has a multi-talented team who are experts in different domains allowing us to provide various services. We specialize in providing astonishing custom eCommerce web development services where you can sell your product with ease. Our professional eCommerce web development services include plenty of different integrations that make your website user-friendly and smoother to use with a lot of functionalities working at the back-end.</p>
                            <ul>
                                <li>
                                    <div className='web-serv-li-bx'>
                                        <img src='images/ecommerce/serv-icon-1.png' alt=''></img>
                                        <p>Business <br />Growth</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='web-serv-li-bx'>
                                        <img src='images/ecommerce/serv-icon-2.png' alt=''></img>
                                        <p>Ecommerce <br /> Solutions</p>
                                    </div>
                                </li>
                            </ul>
                            <div className='bnr-btn about-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='serv-mid-img-ecom'>
                            <img src='images/ecommerce/serv-img-side.png' alt=''></img>
                            <div className='serv-web-quality-txt'>
                                <img src='images/ecommerce/serv-icon-3.png' alt=''></img>
                                <p>Top Quality <br />eCommerce Solutions</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='web-serv-main-slider'>
                            <WebServSlider />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='cta-sec cta-ecommerce-sec'>
                <div className='container'>
                    <div className='row'>                        
                        <div className='col-sm-6'>
                            <div className='cta-side-img cta-side-img-web'>
                                <img src='images/ecommerce/cta-side-img.png' alt=''></img>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='cta-txt web-cta-txt hd-txt'>
                                <h2>Your Gateway to <br />eCommerce Success</h2>
                            </div>
                            <div className='bnr-btn about-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                            <div className='cta-tel'>
                                <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                                <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                            </div>
                        </div>
                    </div>
                </div>
    </section>

    <section className='portfolio-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
        <div className='container'>
            <div className='row'>
                <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                <img className='port-af-img' src='images/port-af.png' alt=''></img>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>A Glimpse Of Work!</h4>
                        <h2>Our Latest Creative Work</h2>
                    </div>
                </div>
                <div className='col-sm-12 p0 main-port-div'>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-1-1.jpg">
                                        <img src="images/ecommerce/port-1.png" alt='' />
                                    </LightgalleryItem>
                                    
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-2-2.jpg">
                                        <img src="images/ecommerce/port-2.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-3-3.jpg">
                                        <img src="images/ecommerce/port-3.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-4-4.jpg">
                                        <img src="images/ecommerce/port-4.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-5-5.jpg">
                                        <img src="images/ecommerce/port-5.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/ecommerce/port-6-6.jpg">
                                        <img src="images/ecommerce/port-6.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
    </section>
    <section className='pricing-sec ecommerce-pricing-sec-pg'>
    <h2 className="mobile-outline-txt-st outline-txt-pricing">Pricing</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Affordability With Quality</h2>
                        <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 pricing-main-col'>
                <div className='pricingtab-slider'>
                    {/* <div className='testi-slider'> */}
                        <div>
                            <div className='pricing-box'>
                                <h3>Beginners E-Commerce Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>789</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Up-to 10 Unique Pages Website</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Up-to 100 Products</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Mini Shopping Cart Integration</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Payment Module Integration</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Corporate E-Commerce Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>1589</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Up-to 15 Unique Pages Website</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Up-to 500 Products</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Conceptual and Dynamic Website</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Easy Product Search</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Product Reviews</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Content Management System (CMS)</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full Shopping Cart Integration</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Payment Module Integration</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Sales &amp; Inventory Management</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Jquery Slider</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Custom Email Addresses</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Dedicated Accounts Manager</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Elite E-Commerce Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>2699</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Pages Website</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> E-Commerce Store Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Product Detail Page Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Products</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Categories</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Product Rating &amp; Reviews</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unique Banner Slider</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Featured Products Showcase</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Full Shopping Cart Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Multi-currency Support</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Online Payment Module Setup</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Facebook Shop Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Sales &amp; Inventory Management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Easy Product Search</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Payment Gateway Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Content Management System/CMS</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Customer Log-in Area</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Mobile Responsive</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Social Media Plugins Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Tell a Friend Feature</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Social Media Pages</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Value Added Services</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Logo Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Icon Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Print Media</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Stationary Design (Business Card, Letterhead &amp; Envelope Design)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Invoice Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Bi-Fold Brochure (OR) 2-Sided Flyer Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Product Catalog Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Sign age Design (OR) Label Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE T-Shirt Design (OR) Car Wrap Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Email Signature Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Facebook, Twitter, YouTube, Google+ &amp; Pinterest Page Designs</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Dedicated Account Manager</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> All Final File Formats</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Advanced E-Commerce Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>4999</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Page Website</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Page Website</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Custom Content Management System (CMS)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unique Pages and UI Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Complete Custom Development</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Process Automation Tools</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Newsfeed Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Social Media Plugins Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Upto 40 Stock images</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 10 Unique Banner Designs</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> JQuery Slider</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Free Google Friendly Sitemap</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Custom Email Addresses</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Social Media Page Designs (Facebook, Twitter, Instagram)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Complete W3C Certified HTML</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Money Back Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Inventory/Shipping/Supplier Module:</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Manage thousands to millions of inventory with ease and check stock levels in real-time. Receive low inventory notifications and generate purchase orders to replenish your stock.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Suppliers Integration (API NEEDED)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Shipper Integration (API NEEDED)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Order management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> LOT numbers and expire date tracking</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Transfer stock between warehouses (If Warehouse - API NEEDED)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Receive stock into a specific warehouse (If Warehouse - API NEEDED)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Fulfill orders from a particular warehouse (If Warehouse - API NEEDED)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Stock Management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Actionable Insights</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Real- Time Visibility</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Inventory Opportunities</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Advanced Features: (API Needed For Suppliers/Warehouse)</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Speak to suppliers during trivial conversations.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Set and send actions to suppliers regarding governance and compliance materials. Place purchasing requests.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Research and answer internal questions regarding procurement functionalities or a supplier/supplier set.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Receiving/filing/documentation of invoices and payments/order requests</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Machine Learning (ML) for Supply Chain Planning (SCP)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Machine Learning for Warehouse Management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Natural Language Processing (NLP) for Data Cleansing and Building Data Robustness</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Invoices &amp; Estimates</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Create beautiful, professional invoices &amp; estimates in just a few seconds and then instantly email them as PDF's directly to your customers or prospects.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Automated Split invoicing</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Automated Combine invoices</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Invoice templates</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Automated Barcode Scanning</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Scan inventory into your orders, generate barcodes for your documents, and search for inventory or documents by scanning barcodes.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Locations and Zones</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Have multiple warehouses, offices, or retail stores? No problem. Easily track where all your inventory is by organizing everything into locations and zones. Organize inventory items using custom attributes such as size, color, and location. View how many you have globally or at each location.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Customer Accounts</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Performance and analytics</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Customization of Personal Details</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Process management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Sales Automation</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Team Collaboration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Marketing Automation</li>
                                    <li><i className="fa-solid fa-arrow-right"></i>  Security</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Integrations</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Mobile Notifications</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Sales Reports</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Trend Analytics</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Forecasting</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Territory Management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Account Management</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Event Integration</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Advanced Data Security</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Purchase Orders</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> With integrated purchase orders, you can easily replenish your inventory levels by ordering more stock and even track when those new items will arrive.</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Partial orders fulfill</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Backordering</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> <strong>Financial Reports</strong></li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Generate extremely detailed reports for your inventory, sales and services. Filter your reports by date-range and category to see what's making you the most money.</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    {/* </div> */}
                </div>
                </div>
            </div>
        </div>
    </section>
    {/* <section className='pricing-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Affordability With Quality</h2>
                        <p>eCommerce web development can sometimes become heavy on the pockets but Blitz Web Solutions can loosen up your pockets by offering pocket-friendly packages, like the ones we are providing.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 pricing-main-col'>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <div className='pricing-box'>
                            <h3>Starter</h3>
                            <div className='amout-bx'>
                                <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                            </div>
                            <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                            <ul>
                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                            </ul>
                            <div className='bnr-btn pricing-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

    <section className='why-choose-sec-inner'>
        <div className='container-fluid'>
        <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>We are experienced in designing revenue-generated e-commerce websites. We believe an eCommerce website is a vital part to upsurge your sales.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/ecommerce/help-icon-1.png' alt='' />
                                <h3>Budget-Friendly</h3>
                                <p>We control the possibility of the web development project, create a budget, and once they're accepted, we stick to them and create exceptional designs in that budget for you.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/ecommerce/help-icon-2.png' alt='' />
                                <h3>Energetic Team</h3>
                                <p>Our entire team consists of energetic designers. They understand your project requirements and meet your requirements and design your store in the most energetic way.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/ecommerce/help-icon-3.png' alt='' />
                                <h3>Fast Turnaround</h3>
                                <p>We deliver your projects on time to avoid any sort of issues related to time commitments, so all the projects that are assigned by you will be delivered within the timeframe provided.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/ecommerce/help-icon-4.png' alt='' />
                                <h3>Experienced Designers</h3>
                                <p>Our web designers have experience of more than ten years. They strive to provide you with eye-grabbing website designs and make your website smoother to use.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<IndustryIconSec />

    <section className='process-inner-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Talk To Client</h4>
                                    <p>We get in touch with our clients and discuss the business idea to reflect it on the website design. We have a detailed discussion with our clients to generate a strategy.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Template Process</h4>
                                    <p>Our experts have detailed meetings within their team to come up with an effective yet captivating design template for the website. Our team chooses a suitable web design as per your requirements.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Testing</h4>
                                    <p>After coming up with beautiful designs, we start testing them to look if they still need some changes or any sort of error might occur in the future.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Product Launch</h4>
                                    <p>After clearing the testing process, the website is now ready to be launched. We don't launch the product before implementing a quality assurance check.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>How We Create Outstanding eCommerce Websites?</h2>
                        <p>eCommerce website process has to have a high-quality e-commerce website design that goes through a custom web growth lifecycle. This is why we are the leaders in building customer-centric e-commerce websites that have eye-drooling web design and e-commerce growth for enhanced performance. We have proven experience to drive traffic and change them into leads. Our process is based on 4 steps only allowing you to understand and participate in each of the steps, because your input matters the most. Take a look at our easy going 4 steps process of creating outstanding eCommerce websites. </p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <TestiSec />
    <Popup />
   
   </>
  )
}
