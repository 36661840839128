import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function Education() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='education-indus-pg'>
        <section className='indusry-main-banner education-bnr'>
            <video src="images/industry/education/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>   
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>Where Education Meets<br /> Digital Ingenuity</h2>
                            <p>Educate people the right way through innovative digital solutions for businesses in education genre.</p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec education-about-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Crafting Tomorrow's Classrooms </h2>
                            <p>Elevate your educational institution's online presence with our expert education web design and development services. Our seasoned professionals specialize in creating captivating, user-friendly websites tailored to your educational vision. From concept to launch, we ensure seamless education website development that engages students, parents, and educators alike. Our team combines creativity and functionality to showcase your institution's unique offerings, fostering a dynamic virtual learning environment. As your dedicated professional education web developer, we are committed to crafting a digital platform that reflects your values and promotes meaningful educational experiences. Join us in shaping the future of learning through exceptional website solutions.</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/education/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec education-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Smart Strategies,<br /> Brighter Minds</h2>
                            <p>Ignite your institution's growth with our specialized education digital marketing services. In the competitive landscape of the education industry, our expertise shines. We navigate the complexities of digital marketing for education, crafting strategies that resonate with your audience. From targeted campaigns to social media engagement, we amplify your online presence and drive enrollment. Our tailored approach understands the nuances of the education industry, ensuring your message reaches the right students, parents, and educators. Partner with us to harness the power of digital marketing and make an impactful connection in the world of education. Your success is our mission.</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/education/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry edu-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Unleash the Power of <br />Digital Learning Today</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry edu-mobile-app-industry'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/education/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>Empower Learning <br />on the Go</h2>
                            <p>Transform learning into a dynamic experience with our top-tier education mobile app services. Seamlessly bridging education and technology, we specialize in crafting intuitive and engaging educational mobile apps. Our education mobile app development is driven by a passion for enhancing learning journeys. From interactive lessons to collaborative tools, we create platforms that empower students and educators alike. Through innovative design and user-friendly interfaces, we ensure that education becomes accessible anywhere, anytime. Join us in revolutionizing education through personalized and adaptable mobile app solutions, shaping the future of learning one app at a time.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/education/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec edu-logo-design-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Logo Design</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/education/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/education/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/education/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/education/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Inspiring Education, Iconic Identity</h2>
                            <p>Capture your institution's essence with our specialized education logo design services. As a leading education industry logo design agency, we understand the significance of a powerful visual identity. Our skilled designers translate your values into a compelling logo that resonates with students, parents, and educators. Through collaboration and creativity, we craft logos that convey expertise and inspire trust. Whether you're a school, university, or e-learning platform, our education logo design services ensure your brand stands out in the educational landscape. Let us shape your identity, reflecting the quality and excellence you bring to the world of education. </p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                {/* <h4>Expert Designers, Exceptional Results</h4> */}
                                <h2>Benefits of Educational <br /> Web Design</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Accessible Learning</h4>
                                        <p>Education digital services break down geographical barriers, allowing students to access learning materials and resources from anywhere in the world. This accessibility caters to diverse learning styles and accommodates busy schedules.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Personalized Learning</h4>
                                        <p>Digital solutions enable tailored learning experiences, catering to individual student needs and pacing. Adaptive technologies analyze progress and adjust content, ensuring optimal comprehension and mastery.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Engagement and Interaction</h4>
                                        <p>Interactive elements, such as quizzes, discussion boards, and multimedia, keep students engaged and motivated. This dynamic approach fosters active participation, promoting deeper understanding.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Efficient Administration</h4>
                                        <p>Education digital services streamline administrative tasks, like registration, grading, and communication, enhancing operational efficiency for educators and institutions.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Data-Driven Insights</h4>
                                        <p>Digital tools generate valuable data on student performance and engagement, enabling educators to make informed decisions for instructional improvement and targeted interventions.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/education/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Global Collaboration</h4>
                                        <p>Through online platforms, students and educators can collaborate globally, exchanging ideas and perspectives. This multicultural interaction enriches the learning experience, cultivating a broader worldview.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry edu-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Your Pathway to <br />Educational Excellence</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <IndustryIconSec />
                              
        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
