import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function RealEstate() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='realestate-indus-pg'>
        <section className='indusry-main-banner realestate-bnr'>
            <video src="images/industry/realestate/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>Embrace the Future <br />of Real Estate Tech</h2>
                            <p>Reach the new heights with top real estate digital marketing solutions by Blitz Web Solution. </p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec realestate-about-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Real Estate Website Wizardry</h2>
                            <p>Elevate your real estate business with our expertly crafted web solutions. Our dedicated team specializes in real estate web design and development services, tailoring every aspect to suit your unique needs. Whether you're a realtor, property manager, or developer, our professional real estate web developers create captivating online experiences that showcase your listings flawlessly. From eye-catching layouts to seamless navigation, we ensure your brand and properties shine. Experience the power of a stunning real estate website developed with precision and passion. Transform your digital presence and engage clients effortlessly with our top-tier real estate website development services.</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/realestate/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec realestate-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Real Estate's Digital Marketing Maestros</h2>
                            <p>Navigating the competitive real estate landscape demands strategic finesse, and our real estate digital marketing services are tailored to lead the way. With a keen understanding of the intricacies of the industry, we amplify your online presence through targeted campaigns. Our expertise in digital marketing for real estate ensures your properties reach the right audience at the right time. From compelling content to precise targeting, we leverage the latest tools to drive leads, conversions, and brand recognition. Elevate your real estate ventures with our industry-savvy digital strategies that bring properties to the forefront and position you for success.</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/realestate/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry realestate-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Transforming Real Estate<br /> Through Digital Innovation</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry realestate-mobile-app-industry'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/realestate/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>Property Power in Your Palm</h2>
                            <p>Introducing our real estate mobile app services, designed to revolutionize how you engage with clients. Seamlessly merging innovation with property, our expertise in real estate mobile app development ensures a dynamic platform that brings listings to life. From virtual tours to instant notifications, we cater to the modern buyer's needs. Our apps enhance accessibility, streamline property searches, and foster interactive communication, fostering stronger client relationships. Elevate your real estate game with our user-centric mobile solutions, putting your properties in the palms of potential buyers and sellers while setting new industry standards.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/realestate/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec realestate-logo-design-sec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/realestate/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/realestate/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/realestate/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/realestate/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Crafting Real Estate Logos with Distinction</h2>
                            <p>Welcome to our realm of real estate logo design mastery. As a premier real estate logo design agency, we recognize the significance of an iconic emblem in the industry. Our real estate logo design services embody your brand's essence, transcending mere graphics to capture your unique identity. With a profound understanding of the real estate industry, we infuse your logo with symbolism that resonates. From elegant simplicity to bold intricacy, each design reflects your values. Elevate your brand recognition with a logo that stands as a testament to your real estate endeavors, etching your mark into this thriving landscape. </p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                {/* <h4>Expert Designers, Exceptional Results</h4> */}
                                <h2>Benefits of Real Estate <br /> Web Design</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Extended Reach</h4>
                                        <p>Real estate digital services open doors to a global audience. Your listings and offerings transcend geographical barriers, attracting potential buyers or tenants from far and wide.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>24/7 Accessibility</h4>
                                        <p>The digital realm never sleeps, offering round-the-clock access to your properties. Buyers can explore listings, view images, and gather information whenever it suits them, enhancing convenience.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Enhanced Visualization</h4>
                                        <p>Immersive virtual tours and high-quality images allow clients to envision themselves within a property's walls. This visualization aids decision-making and reduces unnecessary physical visits.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Precise Targeting</h4>
                                        <p>Digital marketing hones in on your ideal audience. Tailored ads and content reach those actively seeking real estate solutions, boosting the likelihood of valuable engagements.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Efficient Management</h4>
                                        <p>Real estate apps streamline processes, from managing listings and appointments to handling client communication. This efficiency optimizes time, allowing you to focus on strategic growth.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/realestate/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Instant Updates</h4>
                                        <p>Real-time alerts keep clients informed about new listings, price changes, or market trends. This immediacy fosters a strong connection and positions you as a responsive industry leader.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry realestate-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Empowering Real Estate <br />Ventures with Tech</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <IndustryIconSec />

        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
