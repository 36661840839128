import { Navigate,  Routes,  Route} from "react-router-dom";
// import logo from './logo.svg';
import './components/assets/css/bootstrap.min.css';
import './components/assets/css/animate.css';
import './components/assets/css/jquery.fancybox.min.css';
import './components/assets/css/fancybox.css';
import './components/assets/css/slick.css';
import './components/assets/css/slick-theme.css';
import './components/assets/css/style.css';
import './components/assets/css/responsive.css';
import './App.css';

// Includes
import Header from './components/includes/Header';
// footer
import Footer from './components/includes/Footer';

import './components/assets/js/slick.js'


import ScrollToTop from './components/includes/ScrollToTop';

import  jQuery from 'jquery';
import Home from "./pages/Home";
import LogoDesign from "./pages/LogoDesign";
import WebsiteDesign from "./pages/WebsiteDesign";
import EcommerceDesign from "./pages/EcommerceDesign";
import WebPortal from "./pages/WebPortal";
import DigitalMarkteting from "./pages/DigitalMarkteting";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import CookiePageIp from "./components/includes/CookiesPageIp";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";
import Error from "./pages/Error";
import MobileApp from "./pages/MobileApp";
import Education from "./pages/Education";
import Gaming from "./pages/Gaming";
import Agriculture from "./pages/Agriculture";
import Automotive from "./pages/Automotive";
import Construction from "./pages/Construction";
import Corporate from "./pages/Corporate";
import Dental from "./pages/Dental";
import Fashion from "./pages/Fashion";
import Fitness from "./pages/Fitness";
import Health from "./pages/Health";
import NonProfit from "./pages/NonProfit";
import RealEstate from "./pages/RealEstate";
import Restaurant from "./pages/Restaurant";

window.jQuery = jQuery;


function App() {
  
  return (
    <ScrollToTop>
    <div>
    <Header />
    <CookiePageIp />
     <Routes >
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/logo-design" element={<LogoDesign />} />
            <Route path="/website-design" element={<WebsiteDesign />} />
            <Route path="/ecommerce-solution" element={<EcommerceDesign />} />
            <Route path="/web-portal" element={<WebPortal />} />
            <Route path="/digital-marketing" element={<DigitalMarkteting />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/mobile-app" element={<MobileApp />} />
            <Route path="/thank-you" element={<ThankYou />} />

            <Route path="/education" element={<Education />} />
            <Route path="/gaming" element={<Gaming />} />
            <Route path="/agriculture" element={<Agriculture />} />
            <Route path="/construction" element={<Construction />} />
            <Route path="/automotive" element={<Automotive />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/dental" element={<Dental />} />
            <Route path="/fashion" element={<Fashion />} />
            <Route path="/fitness" element={<Fitness />} />
            <Route path="/health-care" element={<Health />} />
            <Route path="/non-profit" element={<NonProfit />} />
            <Route path="/real-estate" element={<RealEstate />} />
            <Route path="/restaurant" element={<Restaurant />} />


            <Route path="/404" element={<Error />} />
            <Route  path="*"  element={<Navigate to="/404" replace />} />
            <Route  path=""  element={<Navigate to="/404" replace />} />

        </Routes>
        <Footer />
        </div>
        </ScrollToTop>
  );
}

export default App;
