import React from 'react'
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
    <section className='footer-bg-st'>
        <div className='container'>
            <div className='row'>
                {/* <img className='blitz-logo-ftr-st' src='images/blitz-logo-ftr.png' alt=''></img> */}
                <div className='col-sm-12 p0'>
                    <div className='ftr-logo-st'>
                        <img src='images/logo.png' alt=''></img>
                    </div>
                </div>
            </div>
        </div>
        <section className='cta-sec ftr-cta-sec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='cta-txt cta-ftr-txt hd-txt'>
                            <h2>Let the Right People Portray Your Brand in the Right Style!</h2>
                        </div>
                        <div className='bnr-btn about-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                        <div className='cta-tel'>
                            <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ftr-main-sec">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 p0 ftr-main-col">
                        <div className="col-sm-7">
                            <div className="services-list">
                                <h4>Services</h4>
                                <ul>
                                    <li><Link to="logo-design"><i className="fa-solid fa-arrow-right"></i> Logo Design</Link></li>                                    
                                    <li><Link to="website-design"><i className="fa-solid fa-arrow-right"></i> Website Design & Development</Link></li>
                                    <li><Link to="ecommerce-solution"><i className="fa-solid fa-arrow-right"></i> Ecommerce Solution</Link></li>
                                    <li><Link to="web-portal"><i className="fa-solid fa-arrow-right"></i> Web Portal</Link></li>
                                    <li><Link to="digital-marketing"><i className="fa-solid fa-arrow-right"></i> Digital Marketing</Link></li>
                                    
                                </ul>
                            </div>
                            <div className="services-list">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><Link to="pricing"><i className="fa-solid fa-arrow-right"></i> Pricing</Link></li>
                                    <li><Link to="portfolio"><i className="fa-solid fa-arrow-right"></i> Portfolio</Link></li>
                                    <li><Link to="about"><i className="fa-solid fa-arrow-right"></i> About Us</Link></li>
                                    <li><Link to="contact"><i className="fa-solid fa-arrow-right"></i> Contact Us</Link></li>
                                    <li><Link to="terms-conditions"><i className="fa-solid fa-arrow-right"></i> Terms Conditions</Link></li>
                                    <li><Link to="privacy-policy"><i className="fa-solid fa-arrow-right"></i> Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="ftr-lft-div">
                                <h4>Contact</h4>
                                <a href="tel:207-072-7323"><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323</a>
                                {/* <a href="#."><i className="fa-solid fa-location-dot"></i> <p>110 Battery St #100, San Francisco, CA 94111, United States</p></a> */}
                                <a href="mailto:sales@blitzwebsolution.co.uk"><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                                
                                <div className="social-icon">
                                    <a className='trust-img' href="https://uk.trustpilot.com/review/blitzwebsolution.co.uk" target='_blank'><img src='images/trust.png' /></a>&nbsp;&nbsp;
                                    <a href="https://www.facebook.com/BlitzWebSolutionUK" target='_blank'><i className="fa-brands fa-facebook"></i></a>
                                    {/* <a href="https://www.facebook.com/Blitz-Web-Solution-108608548055973" target='_blank'><i className="fa-brands fa-facebook-f"></i></a>
                                    
                                    <a href="https://www.linkedin.com/company/blitzwebsolution/" target='_blank'><i className="fa-brands fa-linkedin-in"></i></a>
                                    <a target="_blank" href="https://www.trustpilot.com/review/blitzwebsolution.com"><img src="images/trustpilot-icon.png" alt="trustpilot" /></a>
                                    <a  target="_blank" href="https://www.reviews.io/company-reviews/store/blitzwebsolution-com"><img src="images/review-icon.png" alt="review" /></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="ftr-copy">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 p0 ftr-copy-main-col">
                        <p>©&nbsp;Copyright 2023 Blitz Web Solution, All Rights Reserved</p>
                        <img src='images/payment.png' alt=''></img>
                    </div>
                </div>
            </div>
        </section>
    </section>
    
    </>
  )
}
