import React, { useEffect } from 'react';
import WebPortalServiceSlider from '../components/WebPortalServiceSlider';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import { LightgalleryItem } from "react-lightgallery";
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function WebPortal() {
    document.title ="Professional Web Portal Development Services In London"; 
	document.getElementsByTagName("META")[2].content="Looking for a web portal development and designing services in London, UK. Blitz Web Solution provide web portal development & design services at affordable prices. ";

    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });



        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });
  return (
    <>

    <section className='inner-banner ecommerce-banner web-portal-bnr'>
        <video src="images/webportal/banner.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='inr-bnr-txt '>
                        <h2>Engage, Impress, and Convert with Our Bespoke Web Portal Designs</h2>
                        <p>At Blitz Web Solutions you can easily find custom web portal development services allowing you to get the web portal for your business quite easily. </p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>

                </div>
            </div>
        </div>
    </section>

    <section className='about-sec-web-portal'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Portal</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='about-portal-img'>
                        <img src='images/webportal/about-side-img.png' alt=''></img>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt about-portal-txt'>
                        <h4>Web Portal Development Company in UK</h4>
                        <h2>Manage Your Website Easily With Top Web Development Services</h2>
                        <p>Blitz Web Solution is a professional web portal development service that helps streamline business processes and enhance productivity. Our team excels in custom web portal development and understand that transparency in workflow and ease of use are important. Following this principle, our web portal development professionals apply all their techniques and design mobile-friendly web portals that assist clients with their business process, build their strong online presence, and take customer interaction to the next level and give your customers the experience of their life. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <WebPortalServiceSlider />

    <section className='cta-sec cta-logo-sec cta-portal-sec'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='cta-txt logo-cta-txt hd-txt'>
                        <h2>Harness the Web with our Web Development!</h2>
                    </div>
                    <div className='bnr-btn about-btn'>
                        <a href='#.' className='get-btn popup-btn'>Get Started</a>
                        <a href='#.' className='chat-btn chats'>Live Chat</a>
                    </div>
                    <div className='cta-tel'>
                        <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                        <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='cta-side-img cta-side-img-logo'>
                        <img src='images/webportal/cta-side-img.png' alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='portfolio-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
        <div className='container'>
            <div className='row'>
                <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                <img className='port-af-img' src='images/port-af.png' alt=''></img>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h4>A Glimpse Of Work!</h4>
                        <h2>Our Latest Creative Work</h2>
                    </div>
                </div>
                <div className='col-sm-12 p0 main-port-div'>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                <LightgalleryItem src="images/webportal/port-1-1.jpg">
                                    <img src="images/webportal/port-1.png" alt='' />
                                </LightgalleryItem>
                                    
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/webportal/port-2-2.jpg">
                                        <img src="images/webportal/port-2.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/webportal/port-3-3.jpg">
                                        <img src="images/webportal/port-3.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/webportal/port-4-4.jpg">
                                        <img src="images/webportal/port-4.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/webportal/port-5-5.jpg">
                                        <img src="images/webportal/port-5.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/webportal/port-6-6.jpg">
                                        <img src="images/webportal/port-6.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                        </div>
            </div>
        </div>
    </section>
    
    <section className='pricing-sec web-pricing-sec-pg'>
                <div className='container'>
                <h2 className="mobile-outline-txt-st outline-txt-pricing">Pricing</h2>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Affordability With Quality</h2>
                                <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 pricing-main-col'>
                            <div className='pricingtab-slider'>
                                {/* <div className='testi-slider'> */}
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Starter</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>4598</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom designed Homepage (Unlimited concepts)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Custom designed Inner pages</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom Made, Interactive, Dynamic &amp; User Friendly Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  High End UI|UX</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom Coding &amp; Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Premium Stock Photos</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Customized CMS Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Cross-platform (Desktop, iPhone, Android, etc.) responsive compatibility</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Striking Hover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Interactive jQuery Slider Banner</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Custom Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  2 Landing Page Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Custom Dynamic Forms</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Enterprise Web Portals (multi-user accounts and roles)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  User Signup Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Client/User Dashboard Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Database Creation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Extensive Admin Panel</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Online Reservation/Appointment/Scheduling Tool</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Online Payment Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Live Chat/Bot Chat Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Downloadable items (eBooks, PDFs, Podcast, Videos)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Multi-Lingual</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  3rd Party API Integrations</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Search Bar for Easy Search</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Social Media Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Social Media Live Feeds Widget (Facebook, Instagram, Pinterest)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Search Engine Indexing (Google, Yahoo, and Bing)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  SEO-friendly coding (Meta-tags, Meta Titles, Meta Description, Keywords, W3C compliant etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  On-page SEO configuration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  404 Redirect</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  XML Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Mailing List Plugin (Optional</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  5 Years Domain Registration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  10 Business Email Address</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Parallax Scrolling</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Fast Load Time</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Security plugins</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Google Analytic Installation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Google Webmaster Tool</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Cross-browser Compatible</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  48-72 Hours Turnaround Time</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   180 Days Free Maintenance (Post-launch support)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   How-to-use Training Manual for CMS</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Industry Specified Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Dedicated Account Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   24/7 Customer Support</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Unlimited Revisions</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   100% Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Money Back Guarantee *</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   100% Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Complete Brand Identity &amp; <strong>($199)</strong></li>
                                                <li><i className="fa-solid fa-arrow-right"></i>   Professional Content/Copywriting – $1000 (Up to 20 Pages) – (Per Page $50)</li>
                                                
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Professional </h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>7998</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom designed Homepage (6x concepts)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Custom designed Inner pages</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Made, Interactive, Dynamic &amp; User Friendly Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> High End UI|UX</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Coding &amp; Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Marketplace Development (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Content Management System</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Sales &amp; Inventory Management</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Products</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Categories</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Premium Stock Photos</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Promotional Banners</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 2 Landing Pages Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Interactive jQuery Slider</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Cross-platform (Desktop, iPhone, Android, etc.) responsive compatibility</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Product Detail Page Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Bulk CSV Products Upload</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Product Return Management System</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Reward pointing system</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Calculators</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Product offers (wish-list, discount options, coupon codes)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multiple Product variation (Color, Size, Quantity, and other attributes)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Intelligent search system with filtering options (search by price, categories, Styles etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Product sorting (Newest, Featured, Popular, Best Seller)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Shipping Merchant Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Dropshipping Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Customer Login/Signup Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Database Creation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Full Shopping Cart Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Product Rating &amp; Reviews</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Automated Emails</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Automated Reminders</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Email Marketing Campaigns</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Tell a Friend Feature</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Guest Checkout</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Shipping Labels</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> News &amp; promotions</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Downloadable items (eBooks, PDFs, Podcast, Videos)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Easy Product Search</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Payment Gateway Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multi-currency Support</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Live Chat/Bot Chat Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Social Media Integration (Facebook, Twitter, LinkedIn)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Social Media Live Feeds Widget (Facebook, Instagram, Pinterest)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 3rd Party API Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Automated Email Authentication</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Engine Indexing (Google, Yahoo, and Bing)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Module-wise Architecture</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Extensive Admin Panel</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 404 Redirect</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> XML Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Parallax Scrolling</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Fast Load Time</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Security plugins</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 5 year free hosting</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 5 year free domain registration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> SEO-friendly coding (Meta-tags, Meta Titles, Meta Description, Keywords, etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> On-page SEO configuration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Friendly Sitemap</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Analytic Installation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Google Webmaster Tool</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> W3C Certified HTML</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Cross-browser Compatible</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 48-72 Hours Turnaround Time</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Business Email Address</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  1 Year Free Maintenance (Post-launch support)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Industry Specified Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Dedicated Account Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  24/7 Customer Support</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Unlimited Revisions</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Unique Design Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Money Back Guarantee *</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  100% Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Professional Content/Copywriting – $1,000 (Up to 20 Pages) – (Per Page $50)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i>  Search Engine Optimization (3 Months - 50 Keywords) - $1,350</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='pricing-box'>
                                            <h3>Business</h3>
                                            <div className='amout-bx'>
                                                <h2><sup>£</sup>9998</h2>
                                            </div>
                                            {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                            <ul>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Custom Design &amp; Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Dating Portal, Job Portal, Professional Network Portal, Social Network Portal, Restaurant Portal, Medical Portal, Enterprise Portal (Any One)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unique, User Friendly, Interactive, Dynamic, High End UI Design</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Banner Designs</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Interactive Sliding Banners</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Special Hover Effects</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Unlimited Stock Images</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> User Signup Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Client/User Dashboard Area</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Coding and Development</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Content Management System (Custom)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Online Payment Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Multi Lingual (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Custom Dynamic Forms (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Shopping Cart Integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Database Creation</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Automated Email Authentication</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Web Traffic Analytics Integration</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 3rd Party API Integrations</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Signup Area (For Newsletters, Offers etc.)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Bar for Easy Search</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Live Feeds of Social Networks integration (Optional)</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Search Engine Submission</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Module-wise Architecture</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Extensive Admin Pane</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Award Winning Team of Expert Designers and Developers</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Deployment</li>
                                                <li className="heading"><i className="fa-solid fa-arrow-right"></i> – Value Added Services</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Complete Source Files</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> Dedicated Project Manager</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                            </ul>
                                            <div className='bnr-btn pricing-btn'>
                                                <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    <section className='why-choose-sec-inner'>
         <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>We exude passion in each project we take on. Our process is simple and the result of coordination, creative genius and professionalism of our creatives.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/webportal/help-icon-1.png' alt='' />
                                <h3>Industry Veterans</h3>
                                <p>Blitz Web Solution is a customer-centric company where our clients mean everything and for that reason we provide them with the services that help them grow and reach the sky.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/webportal/help-icon-2.png' alt='' />
                                <h3>Scalable Solutions</h3>
                                <p>We understand that businesses grow and evolve. Our web portal development services are built with scalability in mind, ensuring that your portal can accommodate increasing user traffic and additional features.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/webportal/help-icon-3.png' alt='' />
                                <h3>Security & Reliability</h3>
                                <p>We prioritize the security of your web portal and your users' data. Our development process includes robust security measures to protect against potential threats and vulnerabilities.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/webportal/help-icon-4.png' alt='' />
                                <h3>Responsive Designs</h3>
                                <p>In today's mobile-centric world, a responsive web portal is crucial to reach and engage a wider audience. Our web portal development services include mobile-responsive designs, allowing your portal to adapt.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/webportal/help-icon-5.png' alt='' />
                                <h3>Continuous Support</h3>
                                <p>Our relationship with clients extends beyond the development phase. We offer ongoing support and maintenance services to keep your web portal running smoothly. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <IndustryIconSec />

    <section className='process-inner-sec'>
         <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Planning and Requirement Gathering</h4>
                                    <p>The first step in web portal development is to thoroughly plan and gather requirements. This involves understanding the objectives and goals of the portal.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Design and Development</h4>
                                    <p>Once the requirements are clear, the development team proceeds with the design and development phase. This step involves creating wireframes and prototypes.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Testing and Quality Assurance</h4>
                                    <p>Testing and quality assurance are critical steps to ensure the web portal functions as intended and deliver a seamless user experience. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Deployment </h4>
                                    <p>Once the web portal passes all tests and is ready for launch, it is deployed to a live server, making it accessible to the intended users. </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>Following Efficient Approach To Make Web Portals</h2>
                        <p>Our web portal development team at Blitz Web Solutions believes in efficiency and perfection, and they are always looking to make things perfect in an efficient way. With a passion for delivering top-notch solutions, our team is committed to creating web portals that not only meet your requirements but exceed your expectations. We understand the value of your time and strive to streamline the development process, ensuring timely delivery without compromising on quality. From the initial ideation to the final implementation, we maintain clear communication channels, keeping you informed at every stage and incorporating your feedback to achieve a result that aligns perfectly with your vision. </p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <TestiSec />
    <Popup />
    </>
  )
}
