import React from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import ThankYouCode from '../components/includes/ThankYouCode';



export default function ThankYou() {
    
  return (
    <>
   <ThankYouCode />
    <section className='inner-banner about-banner thank-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                    <h2>Thank<span className="blue-txt">You</span></h2>
                    <p >For considering Blitz Web Solution for your project. We have received your online inquiry and forwarded your information to one of our Account <br />Directors who will be in touch with you shortly. We look forward to working with you.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
<TestiSec/>
    <Popup />
    </>
  )
}
