import React, { Component } from "react";

import Slider from "react-slick";

export default class MobileServicesSlider extends Component {
    render() {
        const mobileServSlider = {
            dots: true,
            arrows:false,
            autoplay:true,
            autoplaySpeed:3000,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            appendDots: dots => (
                <div
                  style={{
                    backgroundColor: "#ddd",
                    borderRadius: "10px",
                    padding: "10px"
                  }}
                >
                  <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
              ),
              customPaging: i => (
                <div
                  style={{
                    width: "30px",
                    color: "blue",
                    border: "1px blue solid"
                  }}
                >
                  {i + 1}
                </div>
              ),
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    dots:true,
                    arrows:false,
                    vertical:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    vertical:false,
                    dots:true,
                    arrows:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
           
        <Slider {...mobileServSlider}>                       
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-1.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-1.png" alt="" ></img>
                        <h4>Mobile Game Development</h4>
                        <div className="mobile-serv-inr-div">
                            <p>Create an extraordinary gaming experience with the best mobile game development company. Our mobile game development services are specially designed to give gamers an experience of their lifetime. </p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-2.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-2.png" alt="" ></img>
                        <h4>Hybrid App Development </h4>
                        <div className="mobile-serv-inr-div">
                            <p>Our hybrid app development services provides you with the perfect app that is a combination of native and web applications that can function properly on different platforms and have access to native features. </p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-3.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-3.png" alt="" ></img>
                        <h4>Wearable App Development</h4>
                        <div className="mobile-serv-inr-div">
                            <p>Blitz Web Solution is a wearable app development company that provides you the liberty to have an application that can be carried everywhere. Our wearable app developers can create apps for every kind of wearable.</p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-4.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-4.png" alt="" ></img>
                        <h4>Blockchain App Development</h4>
                        <div className="mobile-serv-inr-div">
                            <p>Our blockchain app developers are experts in creating a blockchain app that is highly transparent and very secure allowing you to have a global audience with extraordinary experience.</p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-5.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-5.png" alt="" ></img>
                        <h4>Android App Development</h4>
                        <div className="mobile-serv-inr-div">
                            <p>Our developers are experts in developing apps for different platforms and they have specialized in in-demand android apps that work on Android devices flawlessly with amazing interfaces.</p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mobile-mserv-box">
                        <img className="moible-mserv-img" src="images/mobile/services-img-mob-6.png" alt="" />
                        <img src="images/mobile/mobile-serv-icon-6.png" alt="" ></img>
                        <h4>iOS App Development </h4>
                        <div className="mobile-serv-inr-div">
                            <p>Just like Android, we have specialized in iOS app development that functions properly on all of the iOS devices. Our iOS apps are designed according to the platform’s requirements making them work without any problems.</p>
                            {/* <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div> */}
                        </div>
                    </div>
                </div>
               
            </Slider>
                    
        );
      }
    }