import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {



  useEffect(() => {
    window.jQuery(function () {
      var app = function () {
        var body = undefined;
        var menu = undefined;
        var menuItems = undefined;
        var init = function init() {
          body = document.querySelector('body');
          menu = document.querySelector('.menu-icon');
          menuItems = document.querySelectorAll('.nav__list-item');
          applyListeners();
        };
        var applyListeners = function applyListeners() {
          menu.addEventListener('click', function () {
            return toggleClass(body, 'nav-active');
          });
        };
        var toggleClass = function toggleClass(element, stringClass) {
          if (element.classList.contains(stringClass)) element.classList.remove(stringClass); else element.classList.add(stringClass);
        };
        init();
      }();
      function openNav() {
        document.getElementById("mySidenav").style.display = "block";
        document.getElementById("main").style.marginLeft = "250px";
      }
      function closeNav() {
        document.getElementById("mySidenav").style.display = "none";
        document.getElementById("main").style.marginLeft = "0";
      }
  
      // eslint-disable-next-line no-unused-expressions
      window.jQuery(".menu_dd").hide(),
      window.jQuery(".arrow_down + i").click((function() {
        // eslint-disable-next-line no-unused-expressions
        window.jQuery(".menu_dd").not(window.jQuery(this).next()).hide(),
          window.jQuery(this).next().stop(!0, !0).slideToggle();
      }));
  
  
  
      window.jQuery(".hover-target-close").click((function() {
        // eslint-disable-next-line no-unused-expressions
        window.jQuery("body").removeClass("nav-active"),
          window.jQuery(".overlay-bg").css("display", "none"),
          window.jQuery(".popupform-main").removeClass("active");
      }));
  
      window.jQuery(".slider").not('.slick-initialized').slick()
  
       window.jQuery(document).ready(function() {
         window.jQuery(".nav-link").hover(function () {
             window.jQuery(".dropdown").removeClass("active");
            //  window.jQuery(".tab").addClass("active"); // instead of this do the below 
             window.jQuery(".dropdown").addClass("active");   
        });
        });
        });
  window.jQuery(document).ready(function() { 
    window.jQuery(".navbar-nav li a").click(function(event) { 
      
       window.jQuery(".navbar-nav li a ").removeClass("active"); //Remove any "active" class 
       window.jQuery(".navbar-nav li", this).addClass("active"); //Add "active" class to selected tab // 
       // $(activeTab).show(); //Fade in the active content 
  });
 });


 
     window.jQuery('.count').each(function () {
      window.jQuery(this).prop('Counter',0).animate({
      Counter: window.jQuery(this).text()
      }, {
      duration: 50000,
      easing: 'swing',
      step: function (now) {
      window.jQuery(this).text(Math.ceil(now));
      }
      });
      });
      window.jQuery('.nav-item a').click(function () {         
        window.jQuery('.popupform-main').removeClass("active");  
     });
     

  //    window.jQuery('.nav-link').click(function() {
  //     window.location.reload(); 
  // });
  window.jQuery('.nav-link').click(function () {                  
  
    window.jQuery('.popupform-main').removeClass("active");  
 });

  window.jQuery(document).ready(function() {
	// Users can skip the loading process if they want.
	
	
	// Will wait for everything on the page to load.
	 window.jQuery(window).bind('load', function() {
		 window.jQuery('.overlay, body').addClass('loaded');
		setTimeout(function() {
			 window.jQuery('.overlay').css({'display':'none'})
		}, 4000)
	});
	
	// Will remove overlay after 1min for users cannnot load properly.
	setTimeout(function() {
		 window.jQuery('.overlay, body').addClass('loaded');
	}, 5000);
})


});
 
  return (
    <>

    
<div className="overlay">
	<div className="overlayDoor"></div>
	<div className="overlayContent">
		<div className="loader">
			{/* <div className="inner"></div> */}
      <img src='images/logo-icon.png' alt=''></img>
		</div>
		
	</div>
</div>


    <header>
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar  navbar-expand-lg ">
              <div className="container-fluid">
                <a className="navbar-brand" href="/"><img src="images/logo.png" alt=''></img></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto w-100 justify-content-center">
                    <li className="nav-item">
                      <Link className="nav-link navlink-drop" data-toggle="dropdown" to="website-design">Web Design & Development
                        <ul className='dropdown-menu'>                         
                          <li><Link className="nav-link" to="web-portal">Web Portal</Link></li>
                          <li><Link className="nav-link" to="ecommerce-solution">Ecommerce Solutions</Link></li>
                        </ul>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="digital-marketing">Digital Marketing</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="logo-design">Logo Design</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="mobile-app">Mobile App</Link>
                    </li>
                    
                    <li className="nav-item">
                      <Link className="nav-link navlink-drop industries-ul-st" data-toggle="dropdown" to="#.">Industries
                        <ul className='dropdown-menu'>                         

                        
                          <li className='nav-link-st-ico'><Link className="nav-link" to="education"><img src='images/nav-icon-1.png' alt=''></img>Education</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="gaming"><img src='images/nav-icon-2.png' alt=''></img>Gaming</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="agriculture"><img src='images/nav-icon-3.png' alt=''></img>Agriculture</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="construction"><img src='images/nav-icon-4.png' alt=''></img>Construction</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="automotive"><img src='images/nav-icon-5.png' alt=''></img>Automotive</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="corporate"><img src='images/nav-icon-6.png' alt=''></img>Corporate</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="dental"><img src='images/nav-icon-7.png' alt=''></img>Dental</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="fashion"><img src='images/nav-icon-8.png' alt=''></img>Fashion</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="fitness"><img src='images/nav-icon-9.png' alt=''></img>Fitness</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="health-care"><img src='images/nav-icon-10.png' alt=''></img>Health Care</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="non-profit"><img src='images/nav-icon-11.png' alt=''></img>Non Profit</Link></li>
                          <li className='nav-link-st-ico'><Link className="nav-link" to="real-estate"><img src='images/nav-icon-12.png' alt=''></img>Real Estate</Link></li>
                          {/* <li className='nav-link-st-ico'><Link className="nav-link" to="restaurant"><img src='images/nav-icon-13.png' alt=''></img>Restaurant</Link></li> */}

                        </ul>
                      </Link>
                    </li>
                   
                  

                    {/* <li className="nav-item">
                      <Link className="nav-link" to="portfolio">Portfolio</Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="pricing">Pricing</Link>
                    </li>
                    
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="contact">Contact Us</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="mobile-nav">
                  <div className="nav-but-wrap">
                    <div className="menu-icon hover-target">
                      <span className="menu-icon__line menu-icon__line-left"></span>
                      <span className="menu-icon__line"></span>
                      <span className="menu_mm">Menu</span>
                    </div>
                  </div>

                  <div className="nav nav-fix-st">
                    <div className="nav__content">
                      <ul className="nav__list">
                        <li className="nav__list-item "><Link to="/" className="hover-target hover-target-close">Home</Link></li>
                        <li className="nav__list-item"><Link to="about" className="hover-target hover-target-close">About</Link></li>
                        <li className="nav__list-item menu_dd_tab">
                          <Link className="hover-target arrow_down hover-target-close" to="logo-design">Logo Design </Link>
                          {/* <i className="fa fa-caret-down " aria-hidden="true"></i>
                          <ul className="menu_dd" style={{display: 'none'}}>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="2d-logo-design">2D Logo Design</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="3d-logo-design">3D Logo Design</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="mascot-logo-design">Mascot Logo Design</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="illustrative-logo-design">Illustrative Logo Design</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="animated-logo-design">Animated Logo Design</Link></li>
                          </ul> */}
                        </li> 
                        <li className="nav__list-item menu_dd_tab">
                          <Link className="hover-target arrow_down hover-target-close" to="website-design">Web Design & Development </Link>
                          <i className="fa fa-caret-down " aria-hidden="true"></i>
                          <ul className="menu_dd" style={{display: 'none'}}>
                            <li  className='nav__list-item' ><Link className="nav-link nav-link hover-target hover-target-close" to="web-portal">Web Portal</Link></li>
                            <li  className='nav__list-item' ><Link className="nav-link nav-link hover-target hover-target-close" to="ecommerce-solution">Ecommerce Solutions</Link></li>
                          </ul>
                        </li> 
                        
                        <li className="nav__list-item menu_dd_tab">
                          <Link className="hover-target arrow_down hover-target-close" to="digital-marketing">Digital Marketing </Link>
                          {/* <i className="fa fa-caret-down " aria-hidden="true"></i>
                          <ul className="menu_dd" style={{display: 'none'}}>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="branding">Branding</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="video-animation">Video Animation</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="search-engine-optimization">Search Engine Optimization</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="web-content-writing">Web Content Writing</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="social-media-marketing">Social Media Marketing</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="reputation-management">Reputation Management</Link></li>
                          </ul> */}
                        </li> 
                        
                        <li className="nav__list-item menu_dd_tab">
                          <Link className="hover-target arrow_down hover-target-close" to="#">Industry </Link>
                          <i className="fa fa-caret-down " aria-hidden="true"></i>
                          <ul className="menu_dd" style={{display: 'none'}}>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="education">Education</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="gaming">Gaming</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="agriculture">Agriculture</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="construction">Construction</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="automotive">Automotive</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="corporate">Corporate</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="dental">Dental</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="fashion">Fashion</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="fitness">Fitness</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="health-care">Health Care</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="non-profit">Non Profit</Link></li>
                            <li className='nav__list-item'><Link className="nav-link hover-target hover-target-close" to="real-estate">Real Estate</Link></li>
                          </ul>
                        </li> 
                        <li className="nav__list-item"><Link to="pricing" className="hover-target hover-target-close">Pricing</Link></li>
                        <li className="nav__list-item"><Link to="portfolio" className="hover-target hover-target-close">Portfolio</Link></li>
                        <li className="nav__list-item"><Link to="contact" className="hover-target hover-target-close">Contact Us</Link></li>
                        {/* <!--   <li className="nav__list-item"><a to="privacy" className="hover-target">Privacy</a></li>
                                <li className="nav__list-item"><a to="terms.php" className="hover-target">Terms & Conditions<a></li> --> */}


                      </ul>
                    </div>
                  </div>
                </div>
                <div className="cta-btn header-btn">
                  <div className="link_wrapper">
                    {/* <Link href="#." className="get-btn popup-btn">Get Started</Link> */}
                    <a href='#.' className='flag-img-1 flag-img'><img src='images/flag/1.png' alt='' /></a>
                    <a href='#.' className='flag-img-2 flag-img'><img src='images/flag/2.png' alt='' /></a>
                    <a href='#.' className='flag-img-3 flag-img'><img src='images/flag/3.png' alt='' /></a>
                    <div className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268.832 268.832">
                        <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      </>

  )
}
