import React, { Component } from "react";

import Slider from "react-slick";

export default class MobilePortfolioSec extends Component {
    render() {
        const mobilePortfolioSlider = {
            dots: true,
            arrows:false,
            autoplay:true,
            autoplaySpeed:3000,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    dots:true,
                    arrows:false,
                    vertical:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    vertical:false,
                    dots:true,
                    arrows:false,
                    centerMode: false,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
           
        <Slider {...mobilePortfolioSlider}>                       
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img2.png" alt=""></img>
                    </div>
                </div>
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img3.png" alt=""></img>
                    </div>
                </div>
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img4.png" alt=""></img>
                    </div>
                </div>
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img5.png" alt=""></img>
                    </div>
                </div>
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img6.png" alt=""></img>
                    </div>
                </div>
                <div>
                    <div className="mob-portf-box">
                        <img src="images/mobile/app-img7.png" alt=""></img>
                    </div>
                </div>
                
               
            </Slider>
                    
        );
      }
    }