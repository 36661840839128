import React, { Component } from "react";
import {Helmet} from "react-helmet";

export default class ThankYouCode extends Component {
    render() {
               return (
            <div className="application">
                <Helmet>
                <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-10879536616/_oYnCMu5kKgYEOib4sMo'});"}`}</script>

                </Helmet> 
            </div>
        );
      }
    }