import React, { useEffect } from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';


export default function Fashion() {
    useEffect(() => {

        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });


        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



    });

  return (
    <>
    <div className='fashion-indus-pg'>
        <section className='indusry-main-banner fashion-bnr'>
            <video src="images/industry/fashion/banner-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='industry-banner-txt'>
                            <h2>Virtual Vogue Elevating <br />Fashion with Brilliance</h2>
                            <p>Want to stay up to date with the current fashion trends? Then get your fashion digital solutions from Blitz Web Solution today.</p>
                            <div className='bnr-btn'>
                                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='industry-about-sec fashion-about-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Development</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Fashion Website Mastery </h2>
                            <p>Step into the world of style with our expert fashion web design and development services. As professional fashion web developers, we curate digital runways that showcase your brand's uniqueness. From sleek aesthetics to seamless navigation, our fashion website development captures the essence of your label. We understand the pulse of the fashion industry and create online spaces that reflect your vision. With a focus on user experience and mobile responsiveness, we ensure your website elevates your brand's allure. Let our team of skilled fashion web developers craft a digital identity that echoes your passion for fashion.</p>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className='about-industry-img'>
                            <img src='images/industry/fashion/about-img.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='empty-sec fashion-empty-sec'>
            <div className='container'>
                <div className='row'>
                </div>
            </div>
        </section>
        <section className='industry-inner-sec education-inr-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Marketing</h2>
            <div className='container'>
                <div className='row'>
                    <img className='indusry-bf-img-inr' src='images/industry/education/inner-bf-img.png' alt=''></img>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side hd-txt'>
                            <h2>Digital Magic <br />for Brands</h2>
                            <p>Step into the spotlight with our tailored fashion digital marketing services. We specialize in creating digital strategies that captivate fashion enthusiasts. Through innovative campaigns, captivating visuals, and strategic social media tactics, we'll ensure your brand flourishes in the digital fashion realm. Our expertise isn't just about boosting visibility; it's about crafting narratives, fostering trends, and engaging with your audience authentically. From influencer partnerships to optimizing e-commerce, we're here to elevate your brand's online presence. Explore the realm where fashion meets digital marketing – where trends are born, stories unfold, and your brand takes center stage.</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='inner-industry-txt-side-img'>
                            <img src='images/industry/fashion/inner-img-1.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='cta-sec-industry fashion-cta-sec-one'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Fashion Forward,<br /> Digitally Driven</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='moible-app-sec-industry fashion-mobile-app-industry'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Mobile Apps</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/fashion/mobile-img-1.png' alt='' />
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt indus-mob-txt'>
                            <h2>Fashion App <br />Transformation</h2>
                            <p>Elevate your fashion brand's presence with our specialized fashion mobile app services. Our fashion mobile app development team brings your style to life on every screen. From personalized shopping experiences to trend alerts, our apps seamlessly blend fashion and technology. Effortlessly showcase your collections, offer exclusive deals, and engage fashion enthusiasts on the go. With user-friendly interfaces and sleek designs, our apps become a virtual fashion haven in the palm of their hands. Discover the future of fashion engagement through our innovative mobile app solutions. Let your brand shine brighter and connect deeper in the ever-evolving world of fashion.</p>
                            {/* <ul>
                                <li>
                                    <img src='images/industry/education/android.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Android App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/ios.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Ios App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src='images/industry/education/cross-platform.png' alt=''></img>
                                    <div className='indus-mob-txt-inr'>
                                        <h4>Cross-Platform App Development</h4>
                                        <p>We provide revolutionary android app development solutions to businesses.</p>
                                    </div>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mob-indus-side-img'>
                            <img src='images/industry/fashion/mobile-img-2.png' alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-industry-sec fashion-logo-design-sec'>
            <h2 class="mobile-outline-txt-st outline-txt-why-choose">Logo Design</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='logo-indus-side-img'>
                            <ul>
                                <li>
                                    <img src='images/industry/fashion/logo-img-1.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/fashion/logo-img-2.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/fashion/logo-img-3.png' alt='' />
                                </li>
                                <li>
                                    <img src='images/industry/fashion/logo-img-4.png' alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Unveil Fashion Identity</h2>
                            <p>Define your fashion brand's identity with our exceptional fashion industry logo design services. As a dedicated fashion logo design agency, we blend style and strategy to create logos that resonate with your brand's essence. From elegant motifs to innovative designs, we craft logos that evoke your fashion industry expertise. Each curve, color, and element is meticulously chosen to symbolize your brand's uniqueness. Elevate your fashion identity with a logo that speaks volumes, whether it's gracing labels, websites, or social media. Partner with us to transform your fashion brand's essence into a visual icon that captures hearts and defines trends.</p>
                            {/* <ul>
                                <li><img src='images/industry/education/check-icon.png' alt='' />2D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />3D Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Iconic Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Illustrative Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Mascot Logo Design</li>
                                <li><img src='images/industry/education/check-icon.png' alt='' />Typography Logo Design</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='logo-design-why industry-sec-logo-benefit edu-indus-logo-benefit'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Benefit</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h4>Benefits of Fashion <br /> Web Design</h4>
                                <h2>Captivate Your Audience<br /> with Emotion-Evoking<br /> Logo Designs</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Global Reach and Brand Visibility</h4>
                                        <p>Fashion digital services expand your brand's reach to a global audience. Through targeted digital strategies, social media, and online platforms, you can connect with fashion enthusiasts worldwide and enhance brand visibility. </p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Enhanced Customer Engagement</h4>
                                        <p>Fashion digital services create immersive experiences that resonate with your audience. Engaging content, interactive features, and personalized recommendations build deeper connections, fostering customer loyalty.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Real-time Trend Adaptation</h4>
                                        <p>Digital services enable you to stay on top of ever-changing fashion trends. With quick updates, timely campaigns, and responsive designs, you can adapt your offerings to match the latest industry shifts.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Data-Driven Insights</h4>
                                        <p>Through digital services, you gain valuable insights into customer preferences, behaviours, and purchasing patterns. This data guides your decision-making, helping you optimize strategies and product offerings.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Direct-to-Consumer Channels</h4>
                                        <p>Fashion digital services empower you to engage directly with consumers through e-commerce platforms and mobile apps. This direct connection enhances customer relationships and allows for tailored communication.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/industry/fashion/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Measurable ROI</h4>
                                        <p>With fashion digital services, you can track and measure the success of your campaigns and strategies. Detailed analytics provide insights into what works, enabling you to allocate resources effectively and achieve a higher return on investment.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </section>
        <section className='cta-sec-industry fashion-cta-sec-two'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h2>Strut Your Brand with<br /> Captivating Fashion Solutions</h2>
                        </div>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                            <a href='mailto:sales@blitzwebsolution.co.uk' className='email-btn'><i class="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                            <a href='tel:207-072-7323' className='tel-btn'><i class="fa-solid fa-phone"></i> 207 072 7323</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='process-inner-main-col'>
                            <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                            <ul>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>01</h4>
                                        </div>
                                        <h4>Discovery and Planning</h4>
                                        <p>In this initial phase, the development team works closely with the client to understand the app's purpose. Based on the gathered information, a detailed project plan is created, outlining the app's features and timeline.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>02</h4>
                                        </div>
                                        <h4>Design and Prototyping</h4>
                                        <p>The design phase begins with creating wireframes and mockups that showcase the app's layout. Once the design is finalized, interactive prototypes may be developed to provide a realistic representation.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>03</h4>
                                        </div>
                                        <h4>Development and Testing</h4>
                                        <p>This is the stage where the app's code is written and the actual development takes place. Throughout the development process, rigorous testing is conducted to identify and fix bugs.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className='process-inr-div'>
                                        <div className='process-num-inr'>
                                            <img src='images/process-sec/process-icon.png' alt=''></img>
                                            <h4>04</h4>
                                        </div>
                                        <h4>Deployment and Maintenance</h4>
                                        <p>Once the app is fully developed and tested, it is submitted to the respective app stores (e.g., Apple App Store, Google Play Store) for review and approval.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='hd-txt'>
                            <h2>Our Mobile App Development Process</h2>
                            <p>At our company, we place a strong emphasis on the value of open communication and collaboration with our esteemed clients. Throughout the exciting journey of designing your mobile app, we make it a point to involve you at every critical stage, seeking your valuable feedback and input to ensure the final product not only meets but exceeds your expectations. We believe that our clients' visions and insights are of utmost importance, and your unique perspective plays a pivotal role in shaping the design process. By fostering an environment of active participation and open dialogue, we create a true partnership that empowers you to have a significant impact on the app's development.</p>
                            <div className='bnr-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       <IndustryIconSec />

        <TestiSec />
        <Popup />
    </div>
    
    </>
  )
}
