import React, { Component } from "react";
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Popup from "./Popup";

export default class DigitalMarktetingPrice extends Component {
    render() {
        const pricingtab = {
            dots: true,
            arrows:false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <>
            <section className='digtal-mrkt-service-sec portfolio-main-pg-sec'>
                <div className='container'>
                    <div className='row'>
                    <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                    <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                    <img className='port-af-img' src='images/port-af.png' alt=''></img>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            
                        </div>
                    </div>
                        <div className='col-sm-12'>
                           
                            {/* <Tabs
                              defaultActiveKey="digital"
                              transition={true}
                              id="noanim-tab-example"
                              className="mb-3"
                            > */}
                              
                              {/* <Tab eventKey="digital" title="Digital Marketing"> */}
                                <Tabs
                                    defaultActiveKey="seo"
                                    transition={true}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                    >
                                        <Tab eventKey="seo" title="SEO">
                                            <Slider {...pricingtab}>
                                                    {/* <div className='testi-slider'> */}
                                                        <div>
                                                            <div className='pricing-box'>
                                                                <h3>Basic Seo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>599</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Campaign Setup (Min 6 Months) </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> In-depth Site Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Duplicacy Check </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Initial Backlinks analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Competition Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keyword Research </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keywords plan 30 </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>On-Page Optimization  </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Title &amp; Meta Tags Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Optimization   </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Page Speed Analysis &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> HTML Code Cleanup &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Internal Link Structuring &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Robots.txt Creation/Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google &amp; Bing Webmaster Tools Setup</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Setup &amp; Integration</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Content and Link Acquisition (Per Month)</b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Blog Writing - 2 </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Guest Blog Outreach -1</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> DOC / PDF Submission </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Infographic Creation &amp; Distribution (1 every 3rd month</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Local Search Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Citation Building</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Classified Submissions </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Monthly Reporting </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Search Engine Rank Report</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> SEC Reports  </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Monthly Action Plan </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>£ Per Month  </b></li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='pricing-box'>
                                                                <h3>Plus Seo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>699</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Campaign Setup (Min 6 Months) </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> In-depth Site Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Content Duplicacy Check </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Initial Backlinks analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Competition Analysis </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keyword Research</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Keywords plan 45</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>On-Page Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Title &amp; Meta Tags Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i>  Content Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Page Speed Analysis &amp; Optimization </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> HTML Code Cleanup &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Internal Link Structuring &amp; Optimization</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Robots.txt Creation/Analysis</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google &amp; Bing Webmaster Tools Setup</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Google Analytics Setup &amp; Integration</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Content and Link Acquisition (Per Month)</b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Blog Writing</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Guest Blog Outreach - 2</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> DOC / PDF Submission </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Infographic Creation &amp; Distribution (1 every 3rd month) </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Local Search Optimization </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Citation Building </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Classified Submissions </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>Monthly Reporting </b></li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Search Engine Rank Report</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> SEO Reports</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i>  Monthly Action Plan </li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> <b>£ Per Month </b></li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='pricing-box pricing-extra-rem'>
                                                                <h3>Professional Logo Package</h3>
                                                                <div className='amout-bx'>
                                                                    <h2><sup>£</sup>99</h2>
                                                                </div>
                                                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                                <ul>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Logo Design Concepts</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> By 4 Industry Based Designers</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope)</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> FREE File Formats (EPS, Ai, GIF, JPEG, PSD)</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                                                </ul>
                                                                <div className='bnr-btn pricing-btn'>
                                                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        <Tab eventKey="smm" title="SMM">
                                            <Slider {...pricingtab}>
                                                {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Basic Smm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>600</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  2 Social Media Channels (Facebook / Instagram) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Social media account setup </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Complete Account Management </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  3 Posts per week (per network) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Custom Editorial calendar (Review before publishing) </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Dedicated account manager </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i>  Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>1200</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 4 Social Media Channels (Facebook / Twitter / Pinterest / Instagram)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Social media account setup</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Complete Account Management 3 Posts per week (per network)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Custom Editorial calendar (Review before publishing)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Call to Action Integration </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> End of Term Report </li>z
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Account Manager </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus Smm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>2000</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 6 Social Media Channels (Facebook / Twitter / Pinterest/ Instagram / Youtube / LinkedIn)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Social media account setup</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Complete Account Management</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 4 Posts per week (per network)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 GIF post per Month </li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Custom Editorial calendar (Review before publishing)(Facebook)</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Call to Action Integration</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 Stock Video Cover Banner</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Monthly Analytic Report</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Direct Access To Social Media Team</li>
                                                            <li><i className="fa-solid fa-arrow-right"></i> Dedicated Budget For Paid Advertising</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        
                                        <Tab eventKey="orm" title="ORM">
                                            <Slider {...pricingtab}>
                                                {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Startup Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>150</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i> 1 Month Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 3</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Basic Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>349</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 3</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
										
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Plus Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>599</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i> Social Bookmarking - 10</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 1</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 15</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                               
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Premium Orm Packagee</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>899</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i> Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Accounts Setup (Trustpilot, Bark, Sitejabber) - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Keywords / Phrases -3</li>
															<li><i className="fa-solid fa-arrow-right"></i> Search positions monitored and protected - top 20</li>
															<li><i className="fa-solid fa-arrow-right"></i> Article writing &amp; submissions</li>
															<li><i className="fa-solid fa-arrow-right"></i> Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i> Social Bookmarking - 15</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog setup (Wordpress, Blogspot, Tumblr etc.) - 2</li>
															<li><i className="fa-solid fa-arrow-right"></i> Blog Posts - 4</li>
															<li><i className="fa-solid fa-arrow-right"></i> Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i> Reviews Writing &amp; Posting - 20</li>
															<li><i className="fa-solid fa-arrow-right"></i> Video making &amp; sharing</li>
															<li><i className="fa-solid fa-arrow-right"></i> Infographics</li>
															<li><i className="fa-solid fa-arrow-right"></i> Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                
                                                <div>
                                                    <div className='pricing-box'>
                                                        <h3>Business Orm Package</h3>
                                                        <div className='amout-bx'>
                                                            <h2><sup>£</sup>1199</h2>
                                                        </div>
                                                        {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                                        <ul>
                                                            <li><i className="fa-solid fa-arrow-right"></i>3 Months Plan</li>
															<li><i className="fa-solid fa-arrow-right"></i>Brand Audit Report &amp; suggestions</li>
															<li><i className="fa-solid fa-arrow-right"></i>Accounts Setup (Trustpilot, Bark, Sitejabber) - 5</li>
															<li><i className="fa-solid fa-arrow-right"></i>Keywords / Phrases -5</li>
															<li><i className="fa-solid fa-arrow-right"></i>Search positions monitored and protected - top 20</li>
															<li><i className="fa-solid fa-arrow-right"></i>Article writing &amp; submissions</li>
															<li><i className="fa-solid fa-arrow-right"></i>Press Release Writing &amp; Submission</li>
															<li><i className="fa-solid fa-arrow-right"></i>Social Bookmarking - 20</li>
															<li><i className="fa-solid fa-arrow-right"></i>Blog setup (Wordpress, Blogspot, Tumblr etc.) - 3</li>
															<li><i className="fa-solid fa-arrow-right"></i>Blog Posts - 6</li>
															<li><i className="fa-solid fa-arrow-right"></i>Business Listings</li>
															<li><i className="fa-solid fa-arrow-right"></i>Reviews Writing &amp; Posting - 25</li>
															<li><i className="fa-solid fa-arrow-right"></i>Video making &amp; sharing</li>
															<li><i className="fa-solid fa-arrow-right"></i>Infographics</li>
															<li><i className="fa-solid fa-arrow-right"></i>Monthly Accomplishment Report</li>
                                                        </ul>
                                                        <div className='bnr-btn pricing-btn'>
                                                            <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                            <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                                        </div>
                                                    </div>
                                                </div>  
                                                {/* </div> */}
                                            </Slider>
                                        </Tab>
                                        
                                        
                                        
                                    </Tabs>                                  
                              {/* </Tab> */}

                             
                          {/* </Tabs> */}
                            
                        </div>
                    </div>
                </div>
            </section>
            
            <Popup />
            </>
        );
      }
    }