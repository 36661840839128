import React, { Component } from "react";

import Slider from "react-slick";

export default class WebPortalServiceSlider extends Component {
    render() {
        const portalservslider = {
            dots: true,
            arrows:false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <section className='services-portal-sec'>
                <h2 className="mobile-outline-txt-st outline-txt-services">Services</h2>
        <div className='container'>
            <div className='row'>
                <div className="col-sm-12">
                    <div className="hd-txt hdd-txt">
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className='col-sm-5'>
                    <div className='hd-txt serv-portal-txt'>
                        <h2>Web Portal Design Service Promoting User Engagement</h2>
                        <p>Blitz Web Solution is an all-in-one solution for all your business needs providing top-notch services to help your business get to the top level. Our diverse solution has helped us in providing professional web portal development services from which you can select. Take a closer look at how our web portal development company can help your business.</p>
                        <div className='bnr-btn about-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
                <div className='col-sm-7'>
                    <div className='serv-portal-slide-main-col'>
                        <Slider {...portalservslider}>
                        {/* <div className='testi-slider'> */}
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-1.png" alt=""></img>
                                    <h3>B2C Web Portal</h3>
                                    <p>Blitz Web Solution ensures web portal development aims to provoke interest in consumers, & build associations. Our web portals offer functionality, content-sharing capabilities & multimedia support in one place.</p>
                                </div>
                            </div>
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-2.png" alt=""></img>
                                    <h3>Community Web Portal</h3>
                                    <p>Blitz Web Solution extends the Web Portal Design Service of the Community Web Portal formed to trigger the attention of users with similar interests, foster connectivity, and manage an online community through useful means.</p>
                                </div>
                            </div>
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-3.png" alt=""></img>
                                    <h3>Education Web Portal</h3>
                                    <p>Blitz Web Solution comprises a team of website portal designers structuring education web portals ideal for the dissemination & promotion of information coming off as the best choice for educational platforms.</p>
                                </div>
                            </div>
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-4.png" alt=""></img>
                                    <h3>Vendor Web Portal</h3>
                                    <p>Blitz Web Solution is leading as a web portal development company with a vendor web portal incorporating a unique feature that aids businesses to collaborate, easily track & execute actions in a simplified manner.</p>
                                </div>
                            </div>
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-5.png" alt=""></img>
                                    <h3>B2B Web Portal</h3>
                                    <p>Our Web Portal Design Service embraces the latest e-commerce practices assisting organizations in advanced correspondence through unique features and functionalities & enabling the possibility of B2B trade activities.</p>
                                </div>
                            </div>
                            <div>
                                <div className="portal-serv-box">
                                    <img src="images/webportal/serv-icon-6.png" alt=""></img>
                                    <h3>Health Web Portal</h3>
                                    <p>Blitz Web Solution delivers proven web portal design services for health web portals covering information accessibility, consultation, and scheduling for improved communication between patients & various healthcare stakeholders.</p>
                                </div>
                            </div>
                            
                            
                        {/* </div> */}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </section>
          
        );
      }
    }