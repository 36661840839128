import React, { useEffect} from 'react';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import IndustryIconSec from '../components/includes/IndustryIconSec';

export default function About() {

    document.title ="About Us - Blitz Web Solution "; 


    useEffect(() => {
        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

    });

  return (
    <>

    <section className='inner-banner about-banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='inr-bnr-txt '>
                        <h2>Empowering Your Online Presence</h2>
                        <p>At Blitz Web Solution, we specialize in transforming your online presence into a powerful business tool. With our innovative web solutions and expert team, we help businesses like yours thrive in the digital landscape. From captivating website designs to robust e-commerce platforms, we craft tailor-made solutions that drive results. </p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='inner-about-main-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose outline-txt-about-hme-sec">About Us</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-5'>
                    <div className='main-about-side-img'>
                        <img src='images/about/about-side-img.png' alt=''></img>
                    </div>
                </div>
                <div className='col-sm-7'>
                    <div className='hd-txt'>
                        <h4>We Are Committed To Our Work</h4>
                        <h2>About Blitz Web Solution</h2>
                        <p>We are a leading web solution brand dedicated to creating impactful online experiences. With a passion for innovation and a commitment to excellence, we offer comprehensive services that encompass website design and development, captivating logo designs, and a wide range of ecommerce solutions.<br />
                        Our team of talented professionals combines technical expertise with artistic flair to craft visually stunning websites that not only attract visitors but also drive conversions. We understand that every business is unique, which is why we tailor our solutions to meet your specific needs and goals.<br />
                        Whether you're a startup, a small business, or a large enterprise, we are here to help you establish a strong online presence and achieve success in the digital landscape. Partner with Blitz Web Solutions and let us bring your vision to life with cutting-edge designs and robust ecommerce solutions.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='rating-sec logo-rating-sec'>
                <div className='container'>
                    <div className='row rating-main-row'>
                        <div className='col-sm-12 rating-main-col'>
                            <div className='rating-list'>
                                <div className='hd-txt'>
                                    <h2> Achievement in the 7 Years </h2>
                                </div>
                                <ul>
                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>1712</span></h2>
                                            <p>Projects Completed</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>7</span>+</h2>
                                            <p>Years Experience</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>38</span>+</h2>
                                            <p>Expert Members</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>99</span>%</h2>
                                            <p>Satisfaction Rate</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='why-choose-sec-inner'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Why Choose Us?</h2>
                        <p>Trusted expertise, exceptional quality, and unrivaled customer satisfaction—our commitment to excellence sets us apart in delivering unparalleled solutions.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                    <div className='why-slider-inner'>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-1.png' alt='' />
                                <h3>Unparalleled<br /> Expertise</h3>
                                <p>Our team of seasoned professionals delivers unmatched knowledge and industry experience.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-2.png' alt='' />
                                <h3>Outstanding<br /> Quality</h3>
                                <p>We pride ourselves on delivering exceptional work that exceeds expectations.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-3.png' alt='' />
                                <h3>Client-Centric<br /> Approach</h3>
                                <p>We prioritize your needs, ensuring personalized solutions tailored to your requirements.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-4.png' alt='' />
                                <h3>Timely<br /> Delivery</h3>
                                <p>Count on us for prompt, reliable, and on-time completion of projects.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-5.png' alt='' />
                                <h3>Competitive<br /> Pricing</h3>
                                <p>We offer competitive rates without compromising on the quality of our services.</p>
                            </div>
                        </div>
                        <div>
                            <div className='why-box-inr'>
                                <img src='images/about/help-icon-6.png' alt='' />
                                <h3>Customer<br /> Satisfaction</h3>
                                <p>Our commitment to your satisfaction drives us to go the extra mile, every time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <IndustryIconSec />

    <section className='process-inner-sec'>
    <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='process-inner-main-col'>
                        <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                        <ul>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>01</h4>
                                    </div>
                                    <h4>Discovery and Analysis</h4>
                                    <p>The first step in any digital solution involves understanding your business needs, goals, and challenges. Through thorough analysis, we identify opportunities for improvement and potential solutions. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>02</h4>
                                    </div>
                                    <h4>Design and Development</h4>
                                    <p>Once the strategy is defined, our skilled team brings the concept to life through thoughtful design and development. From user interface (UI) design to backend implementation, we do them all.</p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>03</h4>
                                    </div>
                                    <h4>Testing and Quality Assurance</h4>
                                    <p>Rigorous testing and quality assurance are vital to delivering a reliable and error-free digital solution. We conduct various tests to verify functionality, usability, security, and performance. </p>
                                </div>
                            </li>
                            <li>
                                <div className='process-inr-div'>
                                    <div className='process-num-inr'>
                                        <img src='images/process-sec/process-icon.png' alt=''></img>
                                        <h4>04</h4>
                                    </div>
                                    <h4>Deployment and Support</h4>
                                    <p>After successful testing, the digital solution is deployed to the desired platforms. We offer continuous support and maintenance to ensure the solution remains up-to-date.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='hd-txt'>
                        <h2>Our Streamlined <br />Process</h2>
                        <p>At Blitz Web Solutions, we take pride in our efficient and result-driven approach to every project. From the initial consultation, we meticulously plan and gather requirements to ensure a clear roadmap. Our talented team then brings your vision to life, combining creativity and cutting-edge technology to build exceptional solutions. Rigorous testing and quality assurance guarantee a seamless experience. Once deployed, we continue to provide ongoing support and maintenance, ensuring your success doesn't waver. With a focus on transparency, timely communication, and delivering superior results, our streamlined process ensures your project is a resounding success, every step of the way.</p>
                        <div className='bnr-btn'>
                            <a href='#.' className='get-btn popup-btn'>Get Started</a>
                            <a href='#.' className='chat-btn chats'>Live Chat</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <TestiSec />
    <Popup />
    </>
  )
}
