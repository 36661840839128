import React, { useEffect } from 'react'
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import { LightgalleryItem } from "react-lightgallery";
import { Link } from 'react-router-dom';

export default function Home() {
  document.title ="London Web Design Agency - Blitz Web Solution "; 
	document.getElementsByTagName("META")[2].content="Blitz Web Solution is a professional web design agency in UK, USA & UAE. We offer creative and custom web design at affordable prices. Call us at 207 072 7323 ";

    useEffect( ()=>{
      if (window.jQuery(window).width() < 767) {
        window.jQuery('.res_slider').slick({
          dots: false,
          infinite: true,
          arrows:true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      }
        window.jQuery(document).ready(function () {
        window.jQuery(".types-list li").hover(function () {
          window.jQuery(".types-list li").removeClass("active");
          // window.jQuery(".tab").addClass("active"); // instead of this do the below 
          window.jQuery(this).addClass("active");
        });
        });
        window.jQuery(document).ready(function() {
          window.jQuery(".bnr-side-img").hover(function () {
              window.jQuery(".main-banner").toggleClass("active");
              window.jQuery("header").toggleClass("active");
              // window.jQuery(".tab").addClass("active"); // instead of this do the below 
              // window.jQuery('.main-banner').addClass("active");   
          });
          });
       
          window.jQuery(document).ready(function() {
          window.jQuery(".icon-block").hover(function () {
              window.jQuery(".main-tech-div").toggleClass("active");
              // window.jQuery(".tab").addClass("active"); // instead of this do the below 
              // window.jQuery('.main-banner').addClass("active");   
          });
          });
          window.jQuery(document).ready(function() {
          window.jQuery(".icon-block").hover(function () {
              window.jQuery(".icon-block").removeClass("active");
              // window.jQuery(".tab").addClass("active"); // instead of this do the below 
              window.jQuery(this).addClass("active");   
          });
          });

          window.jQuery(document).ready(function(){

            window.jQuery('.hover').hover(function(){
                var value=window.jQuery(this).attr('data-src');
                window.jQuery('#cord-cover').attr('src', value);
            });
            
            });
        
      
        window.jQuery("img").attr("alt", "National Logo Design");
      
        window.jQuery('.industries-slider').slick({
          dots: true,
          infinite: false,
          speed: 300,
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
       
        window.jQuery('.testi-slider').slick({
          dots: false,
          arrows:true,
          infinite: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
      
    
        
    
     });
  return (
    <>
        <section className='main-banner main-bnr-default'>
            <img className='banner-img1' src='images/banner-img.png' alt=''></img>
            <img className='banner-img2' src='images/banner-img2.png' alt=''></img>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='bnr-txt'>
                            <h1><span>Enlightening Brands With</span> <br />Innovative Digital Services</h1>
                            <p>In today’s rapidly evolving digital landscape, staying ahead is crucial. Our innovative digital solutions empower your brand to stay on top in the digital realm with confidence. Blitz Web Solutions covers all the aspects from designing and development to digital marketing to make your brand stand out.</p>
                            <ul>
                                <li><i className="fa-solid fa-chevron-right"></i> Seamless Website Development</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Unique Brand Identity</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Result-oriented Digital Marketing</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Captivating Web Designs</li>
                            </ul>
                            <div className='bnr-btn'>
                                <a href='#!' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='bnr-side-img'>
                          <img className='bulb' src='images/bulb.png' alt=''></img>
                          <img className='bulb-glw' src='images/bulb-glow.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className='main-banner main-banner-usa'>
            <img className='banner-img1' src='images/new-banner/dubai-color.png' alt=''></img>
            <img className='banner-img2' src='images/new-banner/dubai-black.png' alt=''></img>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='bnr-txt'>
                            <h1><span>Enlightening Brands With</span> <br />Innovative Digital Services</h1>
                            <p>In today’s rapidly evolving digital landscape, staying ahead is crucial. Our innovative digital solutions empower your brand to stay on top in the digital realm with confidence. Blitz Web Solutions covers all the aspects from designing and development to digital marketing to make your brand stand out.</p>
                            <ul>
                                <li><i className="fa-solid fa-chevron-right"></i> Seamless Website Development</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Unique Brand Identity</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Result-oriented Digital Marketing</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Captivating Web Designs</li>
                            </ul>
                            <div className='bnr-btn'>
                                <a href='#!' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='bnr-side-img'>
                          <img className='bulb' src='images/bulb.png' alt=''></img>
                          <img className='bulb-glw' src='images/bulb-glow.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <section className='main-banner main-banner-uk'>
            <img className='banner-img1' src='images/new-banner/uk-color.png' alt=''></img>
            <img className='banner-img2' src='images/new-banner/uk-black.png' alt=''></img>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='bnr-txt'>
                            <h1><span>Enlightening Brands With</span> <br />Innovative Digital Services</h1>
                            <p>In today’s rapidly evolving digital landscape, staying ahead is crucial. Our innovative digital solutions empower your brand to stay on top in the digital realm with confidence. Blitz Web Solutions covers all the aspects from designing and development to digital marketing to make your brand stand out.</p>
                            <ul>
                                <li><i className="fa-solid fa-chevron-right"></i> Seamless Website Development</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Unique Brand Identity</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Result-oriented Digital Marketing</li>
                                <li><i className="fa-solid fa-chevron-right"></i> Captivating Web Designs</li>
                            </ul>
                            <div className='bnr-btn'>
                                <a href='#!' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='bnr-side-img'>
                          <img className='bulb' src='images/bulb.png' alt=''></img>
                          <img className='bulb-glw' src='images/bulb-glow.png' alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className='about-sec'>
        <h2 className="mobile-outline-txt-st outline-txt-why-choose outline-txt-about-hme-sec">Web Solution</h2>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='about-txt hd-txt'>
                  <h4>Strategies That Drive Great Results</h4>
                  <h2>Partner With Our Leading-edge Agency For Unmatched Results</h2>
                  <p>Blitz Web Solutions is a creative web design agency that works tirelessly to make your brand stand out among the competitors. We offer various services that help your brand in every way. From website design and development to top-tier digital marketing services, we empower your brand to soar.</p>
                  <div className='bnr-btn about-btn desk_btn'>
                    <a href='#.' className='get-btn popup-btn'>Get Started</a>
                    <a href='#.' className='chat-btn chats'>Live Chat</a>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='about-list'>
                  <ul>
                    <li>
                      <div className='about-sol-bx about-sol-bx1'>
                        <span>01</span>
                        <i className="fa-solid fa-arrow-right"></i>
                        <h4>Web Design & Development</h4>
                      </div>
                    </li>
                    <li>
                      <div className='about-sol-bx about-sol-bx2'>
                        <span>02</span>
                        <i className="fa-solid fa-arrow-right"></i>
                        <h4>Logo Designing Services</h4>
                      </div>
                    </li>
                    <li>
                      <div className='about-sol-bx about-sol-bx3'>
                        <span>03</span>
                        <i className="fa-solid fa-arrow-right"></i>
                        <h4>Digital Marketing Services</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-sm-12'>
                <div className='bnr-btn about-btn mobil_btn'>
                  <a href='#.' className='get-btn popup-btn'>Get Started</a>
                  <a href='#.' className='chat-btn chats'>Live Chat</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='cta-sec'>
          <div className='container'>
            <div className='row'>
              <img className='cta-side-icon-st-1' src='images/cta-side-icon-1.png' alt=''></img>
              <img className='cta-side-icon-st-2' src='images/cta-side-icon-2.png' alt=''></img>
              <img className='cta-side-icon-st-3' src='images/cta-side-icon-3.png' alt=''></img>
              <div className='col-sm-12'>
                <div className='cta-txt hd-txt'>
                  <h2>Unleash the Digital Revolution: <br />Where Innovation Meets Imagination</h2>
                </div>
                <div className='bnr-btn about-btn'>
                    <a href='#.' className='get-btn popup-btn'>Get Started</a>
                    <a href='#.' className='chat-btn chats'>Live Chat</a>
                  </div>
                  <div className='cta-tel'>
                    <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                    <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <section className='technology-stack'>
        <h2 className="mobile-outline-txt-st outline-txt-why-choose outline-txt-industries-hme-sec">Technology</h2>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className="main main-tech-div">
                            <div className="big-circle">
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-1.png" src="images/tech-img-1.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-2.png" src="images/tech-img-2.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-3.png" src="images/tech-img-3.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-4.png" src="images/tech-img-4.png" alt="" />
                                </div>
                            </div>
                            <div className="circle">
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-5.png" src="images/tech-img-5.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-6.png" src="images/tech-img-6.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-7.png" src="images/tech-img-7.png" alt="" />
                                </div>
                                <div className="icon-block">
                                    <img className="hover" data-src="images/tech-logos/tech-img-8.png" src="images/tech-img-8.png" alt="" />
                                </div>
                            </div>
                            <div className="center-logo">
                                <img id="cord-cover" src="images/tech-logos/tech-img-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='hd-txt tech-web-txt'>
                            <h2>Technology <span>Stack</span>Redefined</h2>
                            <p>Experience the future of innovation with our redefined technology stack. Unleash the power of cutting-edge tools and frameworks for enhanced efficiency and unparalleled performance.</p>
                            <div className="bnr-btn about-btn" bis_skin_checked="1"><a href="#." className="get-btn popup-btn">Get Started</a><a href="#." className="chat-btn chats">Live Chat</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='portfolio-sec'>
        <h2 className="mobile-outline-txt-st outline-txt-portfolio">Portfolio</h2>
                <div className='container'>
                    <div className='row'>
                        <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                        <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                        <img className='port-af-img' src='images/port-af.png' alt=''></img>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h4>A Glimpse of Our Work!</h4>
                                <h2>Some Of Our Best Work</h2>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 main-port-div'>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                <LightgalleryItem src="images/port-1-1.jpg">
                                    <img src="images/port-1-1.jpg" alt='' />
                                </LightgalleryItem>
                                    
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-2-2.jpg">
                                        <img src="images/port-2-2.jpg" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-3-3.jpg">
                                        <img src="images/port-3-3.jpg" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-4-4.jpg">
                                        <img src="images/port-4-4.jpg" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-5-5.jpg">
                                        <img src="images/port-5-5.jpg" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-6-6.jpg">
                                        <img src="images/port-6-6.jpg" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <section className='how-sec'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='hd-txt'>
                  <h4>From Vision to Victory </h4>
                  <h2>How We Propel Your Company To New Heights?</h2>
                </div>
              </div>
              <div className='col-sm-12 p0 main-how-col'>
                <ul className='how-list-st res_slider'>
                  <li>
                    <div className='how-box'>
                      <h3>Enhanced Online Presence</h3>
                      <p>Our custom web design agency can help you establish a strong online presence allowing you to reach a wider audience, and increase brand visibility.</p>
                      <img src='images/help-icon-1.png' alt=''></img>
                    </div>
                  </li>
                  <li>
                    <div className='how-box'>
                      <h3>Improved Customer Engagement</h3>
                      <p>We enable your company to engage with customers on various platforms, such as social media and live chat, building lasting relationships.</p>
                      <img src='images/help-icon-2.png' alt=''></img>
                    </div>
                  </li>
                  <li>
                    <div className='how-box'>
                      <h3>Streamlined Operations</h3>
                      <p>As a top web design agency, we streamline your business operations by automating tasks, improving efficiency, and reducing errors.</p>
                      <img src='images/help-icon-3.png' alt=''></img>
                    </div>
                  </li>
                  <li>
                    <div className='how-box'>
                      <h3>Data-Driven Insights</h3>
                      <p>Blitz Web Solutions provides valuable data and analytics allowing you to monitor the behavior of your customers and targeted audience.</p>
                      <img src='images/help-icon-4.png' alt=''></img>
                    </div>
                  </li>
                  <li>
                    <div className='how-box'>
                      <h3>Targeted Marketing Campaigns</h3>
                      <p>We offer precise marketing campaigns where you can get personalized marketing campaigns according to your niche and your audience.</p>
                      <img src='images/help-icon-5.png' alt=''></img>
                    </div>
                  </li>
                  <li>
                    <div className='how-box'>
                      <h3>Scalability and Flexibility</h3>
                      <p>We can easily scale the growth of your company, accommodating increased demands; we also offer flexibility in terms of customization.</p>
                      <img src='images/help-icon-6.png' alt=''></img>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className='web-solution-sec'>
        <h2 className="mobile-outline-txt-st outline-txt-consultation">Web consultation</h2>
          <div className='container'>
            <div className='row'>
              <img className='web-sol-bg-st' src='images/web-sol-bg.png' alt=''></img>
              <div className='col-sm-12'>
                <div className='hd-txt'>
                  <h2>Maximize Your Business Growth with a Results-Driven Web Solution Company</h2>
                </div>
              </div>
              <div className='col-sm-12 main-web-solution-col'>
                <div className='col-sm-6'>
                  <div className='web-sol-lft-col'>
                    <p>Blitz Web Solutions ensure effective web strategies and services cost-effectively. Our professionals generate logos, websites, and digital solutions with visually appealing features and a result-oriented approach</p>
                    <ul className='res_slider'>
                      <li>
                        <div className='web-sol-bx'>
                          <img src='images/web-sol-icon-1.png' alt=''></img>
                          <div className='web-sol-txt'>
                            <h3>Competitive Approach</h3>
                            <p>	We work for client brands’ stand to let their website win the competition. We follow a competitive approach and work for client brands to make them stand out in the competition.</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className='web-sol-bx'>
                          <img src='images/web-sol-icon-2.png' alt=''></img>
                          <div className='web-sol-txt'>
                            <h3>Customer-Centric</h3>
                            <p>Our services are extremely customer-centric as we make the consumer the priority in all web-based developments.</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className='web-sol-bx'>
                          <img src='images/web-sol-icon-3.png' alt=''></img>
                          <div className='web-sol-txt'>
                            <h3>Quick Turnaround</h3>
                            <p>At our professional web design agency, we try to deliver projects in the promised period as we understand the worth of customer time.</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='web-sol-rft-div'>
                    {/* <img src='images/web-sol-side-img.png' alt=''></img> */}
                    {/* <video loop="" muted="" autoplay="" playsinline="" className="lazy loaded" data-was-processed="true"> 
                    <source type="video/mp4" data-src="images/home-loop.mp4" src="images/home-loop.mp4" /></video> */}
                    
                    <video src="images/home-loop.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                    <h2>Unlock <span>Limitless</span> Potential</h2>
                    <p>Being a professional web design agency, we offer a lot of various solutions that will help you unlock your business’s potential.</p>
                    <div className='bnr-btn'>
                      <a href='#.' className='get-btn popup-btn'>Get Started</a>
                      <a href='#.' className='chat-btn chats'>Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='industries-sec'>
           <h2 className="mobile-outline-txt-st outline-txt-why-choose outline-txt-industries-hme-sec">Industries</h2>
          <div className='container-fluid p0'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='hd-txt'>
                  <h4>Industries We Serve</h4>
                  <h2>Helping Business in All Domains</h2>
                  <p>As an extremely successful web design agency, we have provided our digital solutions to businesses across various industries. Take a look: </p>
                </div>
              </div>
              <div className='col-sm-12 p0 main-industries-slider-col'>
                <div className='industries-slider'>
                  <div>
                    <Link className="nav-link" to="/agriculture">
                      <div className='indus-box indus-box-1'>
                        <h3>Agriculture</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/automotive">
                      <div className='indus-box indus-box-2'>
                        <h3>Automotive</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/construction">
                      <div className='indus-box indus-box-3'>
                        <h3>Construction</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/corporate">
                      <div className='indus-box indus-box-4'>
                        <h3>Corporate</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/dental">
                      <div className='indus-box indus-box-5'>
                        <h3>Dental</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/education">
                      <div className='indus-box indus-box-6'>
                        <h3>Education</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/fashion">
                      <div className='indus-box indus-box-7'>
                        <h3>Fashion</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/fitness">
                      <div className='indus-box indus-box-8'>
                        <h3>Fitness</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/gaming">
                      <div className='indus-box indus-box-9'>
                        <h3>Gaming</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/health-care">
                      <div className='indus-box indus-box-10'>
                        <h3>Health</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/non-profit">
                      <div className='indus-box indus-box-11'>
                        <h3>Non Profit</h3>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link className="nav-link" to="/real-estate">
                      <div className='indus-box indus-box-12'>
                        <h3>Real Estate</h3>
                      </div>
                    </Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>


        
        
    <TestiSec />
<Popup />
    </>
  )
}
