import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import TestiSec from '../components/includes/TestiSec';
import Popup from '../components/includes/Popup';
import PortfolioTab from '../components/includes/PortfolioTab';
import IndustryIconSec from '../components/includes/IndustryIconSec';



export default function LogoDesign() {
    document.title ="Custom & Branding Logo Design Services In London "; 
	document.getElementsByTagName("META")[2].content="If you are looking for best logo design services in London, UK. Blitz Web Solution provides custom and creative logo design services for your brand. ";
    useEffect(() => {


        window.jQuery('.pricingtab-slider').slick({
            dots: true,
        arrows:false,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]
        });
   
        window.jQuery('.logo-why-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1750,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1650,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots:true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });

        window.jQuery('.logo-services-slider').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });



        window.jQuery('.why-slider-inner').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        dots:true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });




    });
    return (
        <>

            <section className='inner-banner logo-banner'>
                <video src="images/logo-pg/logo-vid.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='inr-bnr-txt '>
                                <h2>Inspire Recognition with Creative Logo Design</h2>
                                <p>Being the best logo design company, we craft logos that create a lasting impact and are recognized instantly.</p>
                                <div className='bnr-btn'>
                                <Link href="#." className="get-btn popup-btn" >Get Started</Link>
                                    <a href='#.' className='chat-btn chats'>Live Chat</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>

                        </div>
                    </div>
                </div>
            </section>
            <section className='logo-design-why'>
                <h2 className="mobile-outline-txt-st outline-txt-why-choose">Designing</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h4>Expert Designers, Exceptional Results</h4>
                                <h2>Captivate Your Audience<br /> with Emotion-Evoking<br /> Logo Designs</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='logo-why-slider'>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-1.png' alt=''></img>
                                        </div>
                                        <h4>Customization</h4>
                                        <p>Our professional logo design service allows you to have a custom logo made according to your theme and business. Our team of designers puts their best in making your logo different and unique every time so you can enjoy creativity and dynamics on your logo. </p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-2.png' alt=''></img>
                                        </div>
                                        <h4>Branding Expertise</h4>
                                        <p>Our team of designers has expertise in branding, so they tailor your logo according to your brand. The logos we provide are versatile and can be used in different mediums allowing you to get it printed or get it on signages with proper colors and sizes.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-3.png' alt=''></img>
                                        </div>
                                        <h4>Versatile File Formats</h4>
                                        <p>By obtaining our branding logo design service, you don’t have to worry about different files, as we provide you with different file formats according to your requirements. If you want the source file in PSD format or want the JPGs, we are going to provide them all.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-4.png' alt=''></img>
                                        </div>
                                        <h4>Intellectual Property Rights</h4>
                                        <p>What we design is totally your property and you will have the rights for it. The logo you want to get designed will be yours and you will have the rights to it, so no one can claim that the logo is theirs as it will be solely yours.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-5.png' alt=''></img>
                                        </div>
                                        <h4>Extensive Portfolio</h4>
                                        <p>If you are confused about what logo will fit best to your business or which design will look good then you can look in the portfolio of our creative logo design services and select from these which will be tailored to your business niche.</p>

                                    </div>
                                </div>
                                <div>
                                    <div className='logo-why-bx'>
                                        <div className='logo-why-icon-bx'>
                                            <img src='images/logo-pg/logo-design-icon-6.png' alt=''></img>
                                        </div>
                                        <h4>Collaborative Approach</h4>
                                        <p>Our team of designers believe in a collaborative approach where they will collaborate with you and branding team so they can deliver the right image of your brand through logo and make them according to your business and needs. </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='rating-sec logo-rating-sec'>
                <div className='container'>
                    <div className='row rating-main-row'>
                        <div className='col-sm-12 rating-main-col'>
                            <div className='rating-list'>
                                <div className='hd-txt'>
                                    <h2> Achievement in the 7 Years </h2>
                                </div>
                                <ul>
                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>1712</span></h2>
                                            <p>Projects Completed</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>7</span>+</h2>
                                            <p>Years Experience</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>38</span>+</h2>
                                            <p>Expert Members</p>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='rating-box'>
                                            <h2><span className='count'>99</span>%</h2>
                                            <p>Satisfaction Rate</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='services-inner-sec logo-services-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Services</h2>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Our Services</h2>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 logo-serv-main-col'>
                            <div className='logo-services-slider'>
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-1'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-1.png' alt=''></img>
                                        <h3>Illustrative Logo</h3>
                                        <p>Numerous illustrative logo ideas combine classic and modern methods based on an easy procedure, unique design, and typography. It's also interesting to note that, as opposed to businesses with traditional styles, those with quirky styles tend to use illustrative logo designs.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-2'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-2.png' alt=''></img>
                                        <h3>Typographic Logo</h3>
                                        <p>This kind of logo reflects the distinctiveness of the brand. This is ideal for drawing clients' attention because it uses bold typefaces. The nice thing about a typographic logo is that it may incorporate several textual designs, such as elegant, somber, careless, or playful ones.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-3'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-3.png' alt=''></img>
                                        <h3>Iconic Logo Design</h3>
                                        <p>Due to the company's essence and basic principles being aligned, Iconic Logo Service is of the utmost importance. Additionally, an iconic logo design enhances the spirit of the company by showcasing the power of design rather than just the name and color.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-4'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-4.png' alt=''></img>
                                        <h3>2D Animated Logo</h3>
                                        <p>An animated 2D logo is an experience that holds the audience's interest for a lot longer than a static logo does. Through beautifully developed 2D animated logos, our 2D animated logo design company helps to sustain customer retention and boost return on investment (ROI).</p>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-2'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-5.png' alt=''></img>
                                        <h3>3D Animated Logo</h3>
                                        <p>A 3D animated logo design is the instant way of catching the attention of the audience allowing them to stare at the logo for a little longer than they do on a static logo design. The 3D animated logo not only catches the eye but leaves a lasting impact thus increasing sales.</p>
                                    </div>
                                </div>
                                
                                <div>
                                    <div className='logo-serv-box logo-serv-bx-3'>
                                        <span></span>
                                        <img src='images/logo-pg/service-icon-6.png' alt=''></img>
                                        <h3>Symbolic Logo</h3>
                                        <p>Symbolic Logos are the go-to brand logo design for business as they are simple and unique which includes a lot of brainstorming to define the brand image. In a symbolic logo, an icon or a shape is used to convey the essence, values and key attributes of the brand.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PortfolioTab />
            {/* <section className='portfolio-sec'>
                <div className='container'>
                    <div className='row'>
                        <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                        <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                        <img className='port-af-img' src='images/port-af.png' alt=''></img>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h4>A Glimpse of Our Work!</h4>
                                <h2>Some Of Our Best Work</h2>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 main-port-div'>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                <LightgalleryItem src="images/port-1.png">
                                    <img src="images/port-1.png" alt='' />
                                </LightgalleryItem>
                                    
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-2.png">
                                        <img src="images/port-2.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-3.png">
                                        <img src="images/port-3.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-4.png">
                                        <img src="images/port-4.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-5.png">
                                        <img src="images/port-5.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='port-bx'>
                                    <LightgalleryItem src="images/port-6.png">
                                        <img src="images/port-6.png" alt='' />
                                    </LightgalleryItem>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='cta-sec cta-logo-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='cta-txt logo-cta-txt hd-txt'>
                                <h2>Crafting Top Class Logos That Make Your Brand Shine</h2>
                            </div>
                            <div className='bnr-btn about-btn'>
                                <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                <a href='#.' className='chat-btn chats'>Live Chat</a>
                            </div>
                            <div className='cta-tel'>
                                <a href='mailto:sales@blitzwebsolution.co.uk'><i className="fa-solid fa-envelope"></i> sales@blitzwebsolution.co.uk</a>
                                <a href='tel:207-072-7323 '><i className="fa-sharp fa-solid fa-phone-volume"></i> 207 072 7323 </a>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='cta-side-img cta-side-img-logo'>
                                <img src='images/logo-pg/cta-side-img.png' alt=''></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='pricing-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Affordability With Quality</h2>
                                <p>Custom logo creations can sometimes become heavy on the pockets but the best logo design company can loosen up your pockets by offering pocket-friendly packages, like the one we are providing.</p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 pricing-main-col'>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='pricing-box'>
                                    <h3>Starter</h3>
                                    <div className='amout-bx'>
                                        <h2><sup>£</sup>20<span><sub>/</sub>mo</span></h2>
                                    </div>
                                    <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p>
                                    <ul>
                                        <li><i className="fa-solid fa-arrow-right"></i> Unlimited chat history</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Full widget customization</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Ticketing system</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Data security</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Automated greetings</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Basic reporting</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Agent groups</li>
                                        <li><i className="fa-solid fa-arrow-right"></i> Multiple brandings</li>
                                    </ul>
                                    <div className='bnr-btn pricing-btn'>
                                        <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                        <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

<section className='pricing-sec logo-pricing-sec-pg'>
<h2 className="mobile-outline-txt-st outline-txt-pricing">Pricing</h2>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='hd-txt'>
                        <h2>Affordability With Quality</h2>
                        <p>We ensure that our digital services packages will fall under your budget, while providing you with superior quality and maximum satisfaction.</p>
                    </div>
                </div>
                <div className='col-sm-12 p0 pricing-main-col'>
                   

                <div className='pricingtab-slider'>
                    {/* <div className='testi-slider'> */}
                        <div>
                            <div className='pricing-box'>
                                <h3>Basic Logo Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>35</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> 3 Custom Logo Design Concepts</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 1 Dedicated Designer</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 4 Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 2 hours TAT</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee *</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Startup Logo Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>65</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> 4 Custom Logo Design Concepts</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> By 2 Designers</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee*</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Professional Logo Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>99</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> Unlimited Logo Design Concepts</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> By 4 Industry Based Designers</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 24 to 48 hours TAT</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE File Formats (EPS, Ai, GIF, JPEG, PSD)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Business Logo Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>149</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Logo Design Concepts</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> By 8 Award Winning Designers</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Free Icon Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE Custom Stationery Design (Letterhead, Business Card, Envelope, Invoice)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Double Side Flyer (OR) Bi-Fold Brochure</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE MS Electronic Letterhead</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Email Signature Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 48 to 72 hours TAT</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Satisfaction Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Unique Design Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Money Back Guarantee</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 100% Ownership Rights</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='pricing-box'>
                                <h3>Logo & Web Package</h3>
                                <div className='amout-bx'>
                                    <h2><sup>£</sup>249</h2>
                                </div>
                                {/* <p>per agent<br /> billed annualy <br /> or <b>£24</b> month-to-month</p> */}
                                <ul>
                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Logo Design Concepts</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> By 8 Award Winning Designers</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Icon Design</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> UNLIMITED Revisions</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 2 Stationary Design Sets (Business Card, Letterhead, Envelope)</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> FREE MS Word Letterhead</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> Free Email Signature</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 3 Page Custom Website</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 2 Stock Photos</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> 2 Banner Designs</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> jQuery Slider</li>
                                    <li><i className="fa-solid fa-arrow-right"></i> All Final Files Format (AI, PSD, EPS, PNG, GIF, JPG, PDF)</li>
                                </ul>
                                <div className='bnr-btn pricing-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started <i className="fa-solid fa-circle-chevron-right"></i></a>
                                    <a href='#.' className='chat-btn chats'>Live Chat <i className="fa-solid fa-circle-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                        
                        
                    {/* </div> */}
                </div>
                </div>
            </div>
        </div>
    </section>

            <section className='why-choose-sec-inner'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Choose Us</h2>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='hd-txt'>
                                <h2>Why Choose Us?</h2>
                                <p>If you are searching for the best logo design services then look no further than Blitz Web Solutions. Our logo design services our the finest you can find online and here is why:</p>
                            </div>
                        </div>
                        <div className='col-sm-12 p0 why-choose-slider-inr-col'>
                            <div className='why-slider-inner'>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/logo-pg/help-icon-1.png' alt='' />
                                        <h3>High Performance</h3>
                                        <p>Services that are interesting from a design perspective are produced by our professional designers and developers. We make sure that visitors to your website continue to find it interesting.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/logo-pg/help-icon-2.png' alt='' />
                                        <h3>Unique Designs</h3>
                                        <p>Together with you, our designers will create designs that are specifically tailored for you. We want to work with you to realize your vision and reach your goals.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/logo-pg/help-icon-3.png' alt='' />
                                        <h3>Highly Attractive</h3>
                                        <p>Our designs are effective enough to assist you in achieving your business goals. We create designs that go with the market trends and strictly follow the design principles.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className='why-box-inr'>
                                        <img src='images/logo-pg/help-icon-4.png' alt='' />
                                        <h3>Value Towards Persistence </h3>
                                        <p>We all work together to go forward in a way that is both effective and efficient. We work to take some of the pressure off of you as a business owner.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IndustryIconSec />

            <section className='process-inner-sec'>
            <h2 className="mobile-outline-txt-st outline-txt-why-choose">Process</h2>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='process-inner-main-col'>
                                <img className='process-inr-bg' src='images/process-sec/process-side-img-bf.png' alt=''></img>
                                <ul>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>01</h4>
                                            </div>
                                            <h4>Discovery & Research</h4>
                                            <p>We begin with understanding your brand’s identity, target audience, and business goals and gather information through extensive research. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>02</h4>
                                            </div>
                                            <h4>Concept Development </h4>
                                            <p>We, then, brainstorm and generate multiple concepts based on the gathered information and explore different design directions and elements. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>03</h4>
                                            </div>
                                            <h4>Design Refinement</h4>
                                            <p>After creating different concepts, we refine them by adding more details and precision and ask for feedback on the made designs. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='process-inr-div'>
                                            <div className='process-num-inr'>
                                                <img src='images/process-sec/process-icon.png' alt=''></img>
                                                <h4>04</h4>
                                            </div>
                                            <h4>Presentation & Delivery</h4>
                                            <p>At last, we put down all the concepts together and create a presentation and deliver it to in whatever format you like.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='hd-txt'>
                                <h2>Striking Logos That<br /> Demand Attention</h2>
                                <p>In today's competitive business landscape, a strong brand identity is crucial for success. And at Blitz Web Solutions, we understand the significance of a captivating logo in representing your unique brand. As a custom logo design service provider in the UK, we are dedicated to creating professional and creative logo designs that leave a lasting impression on your target audience.</p>
                                <div className='bnr-btn'>
                                    <a href='#.' className='get-btn popup-btn'>Get Started</a>
                                    <a href='#.' className='chat-btn chats'>Live Chat</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TestiSec />
            <Popup />
        </>
    )
}
