import React, { Component } from "react";
import Slider from "react-slick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { LightgalleryItem } from "react-lightgallery";

export default class PortfolioMainTab extends Component {
    render() {
        const portfolioMainTab = {
            dots: true,
            arrows:false,
            rows:2,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    rows:1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
              ]
        };
        return (
            <>
            <section className='digtal-mrkt-service-sec portfolio-main-pg-sec'>
                <div className='container'>
                    <div className='row'>
                    <img className='port-bg' src='images/port-bg-af-img.png' alt=''></img>
                    <img className='port-bf-img' src='images/port-bf.png' alt=''></img>
                    <img className='port-af-img' src='images/port-af.png' alt=''></img>
                    <div className='col-sm-12'>
                        <div className='hd-txt'>
                            <h4>A Glimpse Of Work!</h4>
                            <h2>Our Latest Creative Work</h2>
                        </div>
                    </div>
                        <div className='col-sm-12'>

                        <Tabs
                              defaultActiveKey="webdesign"
                              unmountOnExit={true}
                                mountOnEnter={true}
                                transition={false}
                              id="noanim-tab-example"
                              className="mb-3"
                            >

                                <Tab eventKey="webdesign" title="Website Design">
                                  <Slider {...portfolioMainTab}>
                                        {/* <div className='testi-slider'> */}
                                        <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-1-1.jpg">
                                                        <img src="images/website-design/port-1.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-2-2.jpg">
                                                        <img src="images/website-design/port-2.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-3-3.jpg">
                                                        <img src="images/website-design/port-3.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-4-4.jpg">
                                                        <img src="images/website-design/port-4.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-5-5.jpg">
                                                        <img src="images/website-design/port-5.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                            <div className='port-bx'>
                                                    <LightgalleryItem src="images/website-design/port-6-6.jpg">
                                                        <img src="images/website-design/port-6.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>

                              <Tab eventKey="logo" title="Logo">
                                <Tabs
                                defaultActiveKey="animation2d"
                                unmountOnExit={true}
                                mountOnEnter={true}
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                >
                                <Tab eventKey="animation2d" title="2D Logos">
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/1.gif">
                                                            <video src="images/logo-pg/port/2d-animated/1.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/2.gif">
                                                            <video src="images/logo-pg/port/2d-animated/2.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/3.gif">
                                                            <video src="images/logo-pg/port/2d-animated/3.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/4.gif">
                                                            <video src="images/logo-pg/port/2d-animated/4.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/5.gif">
                                                            <video src="images/logo-pg/port/2d-animated/5.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/2d-animated/6.gif">
                                                            <video src="images/logo-pg/port/2d-animated/6.mp4" muted autoPlay={"autoplay"} preload="auto" loop ></video>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                <Tab eventKey="animation3d" title="3D Logos">
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                            <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/1.gif">
                                                            <img src="images/logo-pg/port/3d-animated/1.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/2.gif">
                                                            <img src="images/logo-pg/port/3d-animated/2.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/3.gif">
                                                            <img src="images/logo-pg/port/3d-animated/3.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/4.gif">
                                                            <img src="images/logo-pg/port/3d-animated/4.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/5.gif">
                                                            <img src="images/logo-pg/port/3d-animated/5.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/3d-animated/6.gif">
                                                            <img src="images/logo-pg/port/3d-animated/6.gif" alt=""></img>
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                <Tab eventKey="iconic" title="Iconic Logos" >
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/1.png">
                                                            <img src="images/logo-pg/port/iconic/1.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/2.png">
                                                            <img src="images/logo-pg/port/iconic/2.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/3.png">
                                                            <img src="images/logo-pg/port/iconic/3.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/4.png">
                                                            <img src="images/logo-pg/port/iconic/4.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/5.png">
                                                            <img src="images/logo-pg/port/iconic/5.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/iconic/6.png">
                                                            <img src="images/logo-pg/port/iconic/6.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                
                                <Tab eventKey="illustrative" title="Illustrative Logos" >
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/1.png">
                                                            <img src="images/logo-pg/port/illustrative/1.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/2.png">
                                                            <img src="images/logo-pg/port/illustrative/2.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/3.png">
                                                            <img src="images/logo-pg/port/illustrative/3.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/4.png">
                                                            <img src="images/logo-pg/port/illustrative/4.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/5.png">
                                                            <img src="images/logo-pg/port/illustrative/5.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/illustrative/6.png">
                                                            <img src="images/logo-pg/port/illustrative/6.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                
                                <Tab eventKey="symbolic" title="Symbolic Logos" >
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/1.png">
                                                            <img src="images/logo-pg/port/symbolic/1.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/2.png">
                                                            <img src="images/logo-pg/port/symbolic/2.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/3.png">
                                                            <img src="images/logo-pg/port/symbolic/3.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/4.png">
                                                            <img src="images/logo-pg/port/symbolic/4.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/5.png">
                                                            <img src="images/logo-pg/port/symbolic/5.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/symbolic/6.png">
                                                            <img src="images/logo-pg/port/symbolic/6.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                
                                <Tab eventKey="typographic" title="Typographic" >
                                    <Slider {...portfolioMainTab}>
                                            {/* <div className='testi-slider'> */}
                                            <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/1.png">
                                                            <img src="images/logo-pg/port/typographic/1.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/2.png">
                                                            <img src="images/logo-pg/port/typographic/2.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/3.png">
                                                            <img src="images/logo-pg/port/typographic/3.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/4.png">
                                                            <img src="images/logo-pg/port/typographic/4.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/5.png">
                                                            <img src="images/logo-pg/port/typographic/5.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                <div>
                                                <div className='port-bx'>
                                                        <LightgalleryItem src="images/logo-pg/port/typographic/6.png">
                                                            <img src="images/logo-pg/port/typographic/6.png" alt='' />
                                                        </LightgalleryItem>                                               
                                                    </div>
                                                </div>
                                                
                                            {/* </div> */}
                                        </Slider>
                                </Tab>
                                </Tabs>
                              </Tab>                            
                              
                              
                              
                              <Tab eventKey="ecommerce" title="Ecommerce Design">
                                  <Slider {...portfolioMainTab}>
                                        {/* <div className='testi-slider'> */}
                                        <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-1-1.jpg">
                                                        <img src="images/ecommerce/port-1.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-2-2.jpg">
                                                        <img src="images/ecommerce/port-2.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-3-3.jpg">
                                                        <img src="images/ecommerce/port-3.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-4-4.jpg">
                                                        <img src="images/ecommerce/port-4.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-5-5.jpg">
                                                        <img src="images/ecommerce/port-5.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                            <div className='port-bx'>
                                                    <LightgalleryItem src="images/ecommerce/port-6-6.jpg">
                                                        <img src="images/ecommerce/port-6.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>

                              <Tab eventKey="webportal" title="Web Portal">
                                  <Slider {...portfolioMainTab}>
                                        {/* <div className='testi-slider'> */}
                                        <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-1-1.jpg">
                                                        <img src="images/webportal/port-1.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-2-2.jpg">
                                                        <img src="images/webportal/port-2.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-3-3.jpg">
                                                        <img src="images/webportal/port-3.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-4-4.jpg">
                                                        <img src="images/webportal/port-4.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-5-5.jpg">
                                                        <img src="images/webportal/port-5.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                            <div className='port-bx'>
                                                    <LightgalleryItem src="images/webportal/port-6-6.jpg">
                                                        <img src="images/webportal/port-6.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>

                              <Tab eventKey="digitalmarket" title="Digital Marketing">
                                  <Slider {...portfolioMainTab}>
                                        {/* <div className='testi-slider'> */}
                                        <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/1.png">
                                                        <img src="images/digital-mrkt/1.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/2.png">
                                                        <img src="images/digital-mrkt/2.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/3.png">
                                                        <img src="images/digital-mrkt/3.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/4.png">
                                                        <img src="images/digital-mrkt/4.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                                <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/5.png">
                                                        <img src="images/digital-mrkt/5.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            <div>
                                            <div className='port-bx'>
                                                    <LightgalleryItem src="images/digital-mrkt/6.png">
                                                        <img src="images/digital-mrkt/6.png" alt=""></img>
                                                    </LightgalleryItem>                                               
                                                </div>
                                            </div>
                                            
                                        {/* </div> */}
                                    </Slider>
                              </Tab>
                             
                          </Tabs>
                           
                            
                            
                        </div>
                    </div>
                </div>
            </section>
            
            
            </>
        );
      }
    }